// OptionStrategies.js

/* eslint-disable react/jsx-pascal-case */
import React, {
    useState,
    useCallback,
    useEffect,
    useRef,
    useReducer,
  } from "react";
  import HomePage from "./HomePage";
  import {
    Autocomplete,
    Box,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    Snackbar,
    Alert,
    Card,
    CardContent,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
  } from "@mui/material";
  import axios from "axios";
  import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
  import { strategyMapping } from "./strategyMapping"; // Import strategy mapping
  
  // Helper functions
  const formatSymbol = (symbol) => {
    return symbol.startsWith("NSE:")
      ? symbol.trim().toUpperCase()
      : `NSE:${symbol.trim().toUpperCase()}`;
  };
  
  const formatNumber = (number) => {
    if (number === undefined || number === null) return "-";
    return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
      number
    );
  };
  
  // Reducer for optionsData
  const optionsReducer = (state, action) => {
    switch (action.type) {
      case "SET_OPTIONS":
        const newState = {};
        action.payload.forEach((item) => {
          const key = `${formatSymbol(item.Symbol)}-${item.Option_type}-${item.Strike_price}`;
          newState[key] = {
            ...item,
            Ltp: item.Ltp,
            Ltpch: item.Ltpch,
            Ltpchp: item.Ltpchp,
            Oi: item.Oi,
            Volume: item.Volume,
          };
        });
        return newState;
  
      case "UPDATE_LTP":
        const updatedState = { ...state };
        action.payload.forEach(({ symbol, ltp, ltpch, ltpchp, oi, vol }) => {
          ["CE", "PE"].forEach((optionType) => {
            // Extract strike price from symbol
            const parts = symbol.split(":");
            if (parts.length < 2) return;
            const symbolDetails = parts[1]; // e.g., "NIFTY50-INDEX-CE-17500"
            const strikeMatch = symbolDetails.match(/(\d{3,5})/); // Adjust regex based on strike price length
            if (!strikeMatch) return;
            const strikePrice = parseFloat(strikeMatch[1]);
            if (isNaN(strikePrice)) return;
            const key = `${formatSymbol(symbol)}-${optionType}-${strikePrice}`;
            if (updatedState[key]) {
              updatedState[key] = {
                ...updatedState[key],
                Ltp: ltp,
                Ltpch: ltpch,
                Ltpchp: ltpchp,
                Oi: oi,
                Volume: vol,
              };
            }
          });
        });
        return updatedState;
  
      default:
        return state;
    }
  };
  

  
  const OptionStrategies = () => {
    // State Declarations
    const [selectedValue, setSelectedValue] = useState(null);
    const [symbolOptions, setSymbolOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedOption, setSelectedOption] = useState("INDEX"); // 'INDEX' or 'EQ'
    const [strikeCount, setStrikeCount] = useState(10);
    const [expiryDates, setExpiryDates] = useState([]);
    const [selectedExpiry, setSelectedExpiry] = useState("");
    const [symbol, setSymbol] = useState("");
    const [vixData, setVixData] = useState(null);
    const [indexData, setIndexData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [connection, setConnection] = useState(null);
  
    const [notification, setNotification] = useState({
      open: false,
      message: "",
      severity: "success",
    });
  
    // Ref for buffering incoming LTP updates
    const ltpUpdatesBuffer = useRef([]);
  
    // Reducer for managing optionsData as an Object
    const [optionsData, dispatchOptionsData] = useReducer(optionsReducer, {});
  
    // State to store strategy results categorized by type
    const [strategyResults, setStrategyResults] = useState({
      Bullish: [],
      Bearish: [],
      Neutral: [],
    });
  
    // State to store Nearest Strike Price (ATM)
    const [nearestStrikePrice, setNearestStrikePrice] = useState(null);
  
    // Fetch symbol suggestions with debouncing
    const fetchSuggestions = useCallback(async (query) => {
      if (query.length === 0) {
        setSymbolOptions([]);
        return;
      }
  
      try {
        const response = await fetch(
          `https://api.arasu.online/api/Symbol/UnderSymSuggestions?query=${encodeURIComponent(
            query
          )}&limit=25`
        );
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
        const data = await response.json();
        setSymbolOptions(data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setError("Failed to fetch symbol suggestions.");
      }
    }, []);
  
    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        fetchSuggestions(inputValue);
      }, 300); // Debounce the API call by 300ms
  
      return () => clearTimeout(delayDebounceFn);
    }, [inputValue, fetchSuggestions]);
  
    // Handle expiry date selection
    const handleExpiryChange = (event) => {
      setSelectedExpiry(event.target.value);
      handleFetchOptionsChain(event.target.value);
    };
  
    // Handle symbol selection
    const handleAutocompleteChange = async (event, newValue) => {
      setSelectedValue(newValue);
      setError("");
  
      const selectedSymbol = newValue?.underSym || "";
      let symbols = "";
  
      if (selectedSymbol) {
        if (selectedOption === "INDEX") {
          symbols = `NSE:${selectedSymbol}50-INDEX`;
        } else if (selectedOption === "EQ") {
          symbols = `NSE:${selectedSymbol}-EQ`;
        } else {
          symbols = `NSE:${selectedSymbol}`;
        }
       
      } else {
        console.error("No symbol selected.");
        setError("No symbol selected.");
        return;
      }
  
      setSymbol(symbols);
  
      try {
        const response = await axios.get(
          "https://api.arasu.online/api/Data/GetOptionsChain",
          {
            params: {
              symbol: symbols,
              strikeCount: strikeCount,
            },
            // headers: {
            //   Authorization: `Bearer ${localStorage.getItem('token')}`,
            // },
          }
        );
  
        console.log("API Response:", response);
  
        if (response.data.status === 1) {
          const expiryData = response.data.data.data.expiryData.map((item) => ({
            date: item.Date,
            expiry: item.Expiry,
          }));
          setExpiryDates(expiryData);
        } else {
          console.error(
            "API returned an unexpected status:",
            response.data.status
          );
          setError("Failed to fetch expiry dates.");
        }
      } catch (err) {
        console.error("Error fetching options chain:", err);
        setError("An error occurred while fetching options chain data.");
      }
    };
  
    // Fetch options chain data based on selected expiry
    const handleFetchOptionsChain = async (expiryValue) => {
      const expiry = expiryValue || selectedExpiry;
      if (!expiry) {
        console.log("No expiry date selected.");
        return;
      }
      console.log("Selected Expiry:", expiry);
  
      setIsLoading(true);
      setError("");
      setVixData(null);
      dispatchOptionsData({ type: "SET_OPTIONS", payload: [] }); // Clear optionsData
      setIndexData(null);
      setStrategyResults({
        Bullish: [],
        Bearish: [],
        Neutral: [],
      }); // Reset Strategy Results
      setNearestStrikePrice(null);
  
      try {
        const response = await axios.get(
          "https://api.arasu.online/api/Data/GetOptionsChain",
          {
            params: {
              symbol: symbol,
              strikeCount: strikeCount,
              expiry: expiry,
            },
            // headers: {
            //   Authorization: `Bearer ${localStorage.getItem('token')}`,
            // },
          }
        );
  
        if (response.data.status === 1) {
          setVixData(response.data.data.data.indiavixData);
          const fetchedIndexData = response.data.data.data.optionsChainData[0];
  
          // Validate fetchedIndexData
          if (
            fetchedIndexData &&
            fetchedIndexData.Description &&
            fetchedIndexData.Ltp !== undefined
          ) {
            setIndexData(fetchedIndexData);
          } else {
            console.error("Fetched Index Data is incomplete:", fetchedIndexData);
            setError("Fetched Index Data is incomplete.");
          }
  
          const optionsChainData =
            response.data.data.data.optionsChainData.slice(1); // Exclude indexData
  
          // Validate optionsChainData
          if (optionsChainData && optionsChainData.length > 0) {
            dispatchOptionsData({ type: "SET_OPTIONS", payload: optionsChainData });
          } else {
            console.error("Options Chain Data is empty or undefined.");
            setError("Options Chain Data is empty.");
          }
  
          // Calculate Nearest Strike Price
          if (fetchedIndexData && fetchedIndexData.Ltp) {
            const strikePrices = getUniqueStrikePrices({
              ...optionsData,
              ...optionsChainData.reduce((acc, option) => {
                const key = `${formatSymbol(option.Symbol)}-${option.Option_type}-${option.Strike_price}`;
                acc[key] = option;
                return acc;
              }, {}),
            });
  
            const atmStrike = findATMStrikePrice(fetchedIndexData.Ltp, strikePrices);

           
             
            setNearestStrikePrice(atmStrike);
            console.log("Nearest Strike Price (ATM):", atmStrike);
          } else {
            setError("Index LTP data is unavailable for ATM strike calculation.");
          }
        } else {
          setError("Failed to fetch options chain data.");
        }
      } catch (err) {
        console.error("Error fetching options chain:", err);
        setError("An error occurred while fetching options chain data.");
      } finally {
        setIsLoading(false);
      }
    };
  
    // Helper functions for strategy calculations
    const getUniqueStrikePrices = (optionsData) => {
      const strikePricesSet = new Set();
  
      Object.values(optionsData).forEach((option) => {
        const strikePrice = parseFloat(option.Strike_price);
        if (!isNaN(strikePrice)) {
          strikePricesSet.add(strikePrice);
        }
      });
  
      const strikePrices = Array.from(strikePricesSet).sort((a, b) => a - b);
      return strikePrices;
    };
  
    const findATMStrikePrice = (currentLtp, strikePrices) => {
      if (!currentLtp || strikePrices.length === 0) return null;
  
      let nearest = strikePrices[0];
      let minDiff = Math.abs(currentLtp - strikePrices[0]);
  
      strikePrices.forEach((strike) => {
        const diff = Math.abs(currentLtp - strike);
        if (diff < minDiff) {
          minDiff = diff;
          nearest = strike;
        }
      });
  
      return nearest;
    };
  
    // Manage SignalR connection and handle incoming messages
    useEffect(() => {
      const token = localStorage.getItem("token"); // Assuming token is stored here
      const newConnection = new HubConnectionBuilder()
        .withUrl("https://api.arasu.online/ltpHub", {
          accessTokenFactory: () => token, // Include token if SignalR hub requires authentication
        }) // Ensure this URL matches your backend hub route
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
  
      setConnection(newConnection);
    }, []);
  
    const indexDataRef = useRef(indexData);
    const vixDataRef = useRef(vixData);
  
    useEffect(() => {
      indexDataRef.current = indexData;
    }, [indexData]);
  
    useEffect(() => {
      vixDataRef.current = vixData;
    }, [vixData]);
  
    // Manage SignalR connection and handle incoming messages
    useEffect(() => {
      if (connection) {
        connection
          .start()
          .then(() => {
            console.log("Connected to SignalR hub");
  
            // Subscribe to ReceiveLtp event
            connection.on("ReceiveLtp", (message) => {
              // Assuming message has symbol, ltp, change, changePercentage, openInterest, volumeTradedToday
              const receivedSymbol = formatSymbol(message.symbol);
              const receivedLtp = message.ltp;
              const change = message.change;
              const changePercent = message.changePercentage;
              const openInterest = message.openInterest;
              const volume = message.volumeTradedToday;
  
              // Update Index Data
              if (
                indexDataRef.current &&
                indexDataRef.current.Description &&
                `NSE:${indexDataRef.current.Description}` === receivedSymbol
              ) {
                setIndexData({
                  ...indexDataRef.current,
                  Ltp: receivedLtp,
                  Ltpch: change,
                  Ltpchp: changePercent,
                });
              }
  
              // Update VIX Data
              if (
                vixDataRef.current &&
                vixDataRef.current.Description &&
                `NSE:${vixDataRef.current.Description}` === receivedSymbol
              ) {
                setVixData({
                  ...vixDataRef.current,
                  Ltp: receivedLtp,
                  Ltpch: change,
                  Ltpchp: changePercent,
                });
              }
  
              // Push the update to the buffer
              ltpUpdatesBuffer.current.push({
                symbol: receivedSymbol,
                ltp: receivedLtp,
                ltpch: change,
                ltpchp: changePercent,
                oi: openInterest,
                vol: volume,
              });
            });
  
            // Handle reconnection events
            connection.onreconnecting((error) => {
              console.warn("SignalR reconnecting:", error);
              setNotification({
                open: true,
                message: "Reconnecting to real-time data...",
                severity: "warning",
              });
            });
  
            connection.onreconnected((connectionId) => {
              console.log("SignalR reconnected. Connection ID:", connectionId);
              setNotification({
                open: true,
                message: "Reconnected to real-time data.",
                severity: "success",
              });
            });
  
            connection.onclose((error) => {
              console.error("SignalR connection closed:", error);
              setNotification({
                open: true,
                message: "Disconnected from real-time data.",
                severity: "error",
              });
            });
          })
          .catch((err) => {
            console.error("Failed to connect to SignalR hub:", err);
            setNotification({
              open: true,
              message: "Failed to connect to real-time data.",
              severity: "error",
            });
          });
  
        // Cleanup on unmount
        return () => {
          connection
            .stop()
            .then(() => console.log("Disconnected from SignalR hub"))
            .catch((err) =>
              console.error("Error disconnecting from SignalR hub:", err)
            );
        };
      }
    }, [connection]);
  
    // Batch process buffered LTP updates
    useEffect(() => {
      const interval = setInterval(() => {
        if (ltpUpdatesBuffer.current.length > 0) {
          // Create a copy of the buffer and clear it
          const updatesToProcess = [...ltpUpdatesBuffer.current];
          ltpUpdatesBuffer.current = [];
  
          // Dispatch to update optionsData
          dispatchOptionsData({ type: "UPDATE_LTP", payload: updatesToProcess });
        }
      }, 50); // Adjust the interval as needed
  
      return () => clearInterval(interval);
    }, []);
  
    // Handle Snackbar close
    const handleCloseNotification = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setNotification((prev) => ({ ...prev, open: false }));
    };
  
    // Handle Category Selection
    const [selectedCategory, setSelectedCategory] = useState(null);
  
    const handleCategoryClick = (category) => {
      setSelectedCategory(category);
      setStrategyResults((prevResults) => ({
        ...prevResults,
        [category]: [], // Clear results for the selected category
      }));
      setNearestStrikePrice(null);
    };
  
    // Handler for strategy execution
    const handleStrategyExecution = (strategyName, strikePrice) => {
    
      if (!strikePrice || strikePrice <= 0) {
        setError(`Invalid strike price (${strikePrice}) for strategy ${strategyName}.`);
        return;
      }
  
      const strategy = strategyMapping[strategyName];
      if (!strategy) {
        setError(`Strategy ${strategyName} not found.`);
        return;
      }
  
      const selectedOptions = strategy.legs(symbol, optionsData, strikePrice);
  
      // Handle special cases where legs might be an object (e.g., Short Iron Condor)
      if (strategyName === "Short Iron Condor") {
        if (!selectedOptions[0].sell || !selectedOptions[1].sell) {
          setError(`Required options data not found for strategy ${strategyName}.`);
          return;
        }
      }
  
      if (selectedOptions.some((opt) => !opt)) {
        setError(`Required options data not found for strategy ${strategyName}.`);
        return;
      }
  
      // Perform the calculation
      let result;
      try {
        if (strategyName === "Short Iron Condor") {
          result = strategy.calculate(selectedOptions[0], selectedOptions[1]);
        } else if (strategyName === "Batman" || strategyName === "Double Plateau") {
          result = strategy.calculate(selectedOptions);
        } else {
          result = strategy.calculate(...selectedOptions);
        }
      } catch (err) {
        console.error(`Error calculating strategy ${strategyName}:`, err);
        setError(`Error calculating strategy ${strategyName}.`);
        return;
      }
  
      // Determine the category of the strategy
      let category = "";
      if (
        [
          "Buy Call",
          "Sell Put",
          "Bull Call Spread",
          "Bull Put Spread",
          "Call Ratio Back Spread",
          "Long Calendar with Calls",
          "Bull Condor",
          "Bull Butterfly",
          "Zero Cost",
          "Range Forward",
          "Long Synthetic Future",
        ].includes(strategyName)
      ) {
        category = "Bullish";
      } else if (
        [
          "Buy Put",
          "Sell Call",
          "Bear Call Spread",
          "Bear Put Spread",
          "Put Ratio Back Spread",
          "Long Calendar with Puts",
          "Bear Condor",
          "Bear Butterfly",
          "Risk Reversal",
          "Short Synthetic Future",
        ].includes(strategyName)
      ) {
        category = "Bearish";
      } else if (
        [
          "Short Straddle",
          "Iron Butterfly",
          "Short Strangle",
          "Short Iron Condor",
          "Batman",
          "Double Plateau",
          "Jade Lizard",
          "Reverse Jade Lizard",
        ].includes(strategyName)
      ) {
        category = "Neutral";
      } else {
        category = "Unknown";
      }
  
      // Update the corresponding category in strategyResults
      setStrategyResults((prevResults) => ({
        ...prevResults,
        [category]: [...prevResults[category], result],
      }));
    };
  
    return (
      <>
        <HomePage />
        <Box sx={{ p: 2, m: 2, backgroundColor: "#f5f5f5", borderRadius: 2 }}>
          <Box sx={{ width: "100%" }}>
            {/* Display Error Messages */}
            {error && (
              <Box sx={{ mb: 2 }}>
                <Alert severity="error" onClose={() => setError("")}>
                  {error}
                </Alert>
              </Box>
            )}
  
            {/* Notification Snackbar */}
            <Snackbar
              open={notification.open}
              autoHideDuration={6000}
              onClose={handleCloseNotification}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseNotification}
                severity={notification.severity}
                sx={{ width: "100%" }}
              >
                {notification.message}
              </Alert>
            </Snackbar>
  
            {/* Input Controls */}
            <Grid
              container
              spacing={2}
              alignItems="center"
              wrap="wrap"
              sx={{
                overflowX: "auto",
              }}
            >
              {/* Autocomplete Component */}
              <Grid item xs={12} sm={6} md={3} minWidth={200}>
                <Autocomplete
                  fullWidth
                  options={symbolOptions}
                  getOptionLabel={(option) => option.underSym || ""}
                  value={selectedValue}
                  onChange={handleAutocompleteChange}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setError(""); // Clear error when input changes
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Symbol"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
  
              {/* Radio Buttons */}
              <Grid item xs={12} sm={4} md={2}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={selectedOption}
                    onChange={(event) => setSelectedOption(event.target.value)}
                  >
                    <FormControlLabel
                      value="INDEX"
                      control={<Radio color="primary" />}
                      label="INDEX"
                    />
                    <FormControlLabel
                      value="EQ"
                      control={<Radio color="primary" />}
                      label="EQ"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
  
              {/* Strike Count Input */}
              <Grid item xs={12} sm={6} md={2} minWidth={150}>
                <TextField
                  label="Strike Count"
                  type="number"
                  value={strikeCount}
                  onChange={(e) => setStrikeCount(Number(e.target.value))}
                  fullWidth
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>
  
              {/* Expiry Dates */}
              {expiryDates.length > 0 && (
                <Grid item xs={12} sm={6} md={2} minWidth={200}>
                  <FormControl fullWidth>
                    <InputLabel id="expiry-select-label">Expiry Date</InputLabel>
                    <Select
                      labelId="expiry-select-label"
                      value={selectedExpiry}
                      label="Expiry Date"
                      onChange={handleExpiryChange}
                    >
                      {expiryDates.map((expiry) => (
                        <MenuItem key={expiry.expiry} value={expiry.expiry}>
                          {expiry.date}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
  
            {/* Loading Indicator */}
            {isLoading && (
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <CircularProgress />
              </Box>
            )}
  
            {/* VIX and Index Data as Cards */}
            {(vixData || indexData) && (
              <Box sx={{ mb: 2, width: "75%" }}>
                <Grid container spacing={1}>
                  {/* Index Data Card */}
                  {indexData && (
                    <Grid item xs={12} sm={6} md={3}>
                      <Card
                        sx={{
                          height: "50px",
                          marginTop: "10px",
                          backgroundColor: "#fff3e0",
                          "&:hover": {
                            boxShadow: 6,
                          },
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={12}>
                              <Typography variant="body2">
                                {(indexData.Description?.replace("-INDEX", " :") ||
                                  "N/A") +
                                  "\u00A0\u00A0" +
                                  formatNumber(indexData.Ltp) +
                                  "\u00A0\u00A0(" +
                                  formatNumber(indexData.Ltpch) +
                                  ")\u00A0\u00A0(" +
                                  formatNumber(indexData.Ltpchp) +
                                  "%)"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
  
                  {/* VIX Data Card */}
                  {vixData && (
                    <Grid item xs={12} sm={6} md={3}>
                      <Card
                        sx={{
                          height: "50px",
                          marginTop: "10px",
                          backgroundColor: "#e3f2fd",
                          "&:hover": {
                            boxShadow: 6,
                          },
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            {vixData.map((item, idx) => (
                              <React.Fragment key={idx}>
                                <Grid item xs={12} sm={6} md={12}>
                                  <Typography variant="body2" color="textPrimary">
                                    {(item.Description?.replace("-INDEX", " :") ||
                                      "N/A") +
                                      "\u00A0\u00A0" +
                                      formatNumber(item.Ltp) +
                                      "\u00A0\u00A0(" +
                                      formatNumber(item.Ltpch) +
                                      ")\u00A0\u00A0(" +
                                      formatNumber(item.Ltpchp) +
                                      "%)"}
                                  </Typography>
                                </Grid>
                              </React.Fragment>
                            ))}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
  
            <Box sx={{ p: 1, width: "100%" }}>
              {/* Category Buttons */}
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                <Button
                  variant={selectedCategory === "Bullish" ? "contained" : "outlined"}
                  size="small"
                  onClick={() => handleCategoryClick("Bullish")}
                  sx={{ m: 1, mt: 4 }}
                >
                  Bullish
                </Button>
                <Button
                  variant={selectedCategory === "Bearish" ? "contained" : "outlined"}
                  size="small"
                  onClick={() => handleCategoryClick("Bearish")}
                  sx={{ m: 1, mt: 4 }}
                >
                  Bearish
                </Button>
                <Button
                  variant={selectedCategory === "Neutral" ? "contained" : "outlined"}
                  size="small"
                  onClick={() => handleCategoryClick("Neutral")}
                  sx={{ m: 1, mt: 4 }}
                >
                  Neutral
                </Button>
              </Box>
  
              {/* Display Selected Category */}
              {selectedCategory && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6" align="center">
                    {selectedCategory} Options
                  </Typography>
                </Box>
              )}
  
              {/* Options Grid */}
              <Grid container spacing={1}>
                {selectedCategory &&
                  Object.keys(strategyMapping)
                    .filter((strategy) => {
                      // Determine category based on strategy name
                      if (
                        [
                          "Buy Call",
                          "Sell Put",
                          "Bull Call Spread",
                          "Bull Put Spread",
                          "Call Ratio Back Spread",
                          "Long Calendar with Calls",
                          "Bull Condor",
                          "Bull Butterfly",
                          "Zero Cost",
                          "Range Forward",
                          "Long Synthetic Future",
                        ].includes(strategy)
                      ) {
                        return selectedCategory === "Bullish";
                      } else if (
                        [
                          "Buy Put",
                          "Sell Call",
                          "Bear Call Spread",
                          "Bear Put Spread",
                          "Put Ratio Back Spread",
                          "Long Calendar with Puts",
                          "Bear Condor",
                          "Bear Butterfly",
                          "Risk Reversal",
                          "Short Synthetic Future",
                        ].includes(strategy)
                      ) {
                        return selectedCategory === "Bearish";
                      } else if (
                        [
                          "Short Straddle",
                          "Iron Butterfly",
                          "Short Strangle",
                          "Short Iron Condor",
                          "Batman",
                          "Double Plateau",
                          "Jade Lizard",
                          "Reverse Jade Lizard",
                        ].includes(strategy)
                      ) {
                        return selectedCategory === "Neutral";
                      } else {
                        return false;
                      }
                    })
                    .map((strategy, index) => (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <Button
                          variant="outlined"
                          size="small"
                          fullWidth
                          color="primary"
                          sx={{
                            borderColor: "primary.main",
                          }}
                          onClick={() =>
                            handleStrategyExecution(
                              strategy,
                              nearestStrikePrice || 0
                            )
                          }
                        >
                          {strategy}
                        </Button>
                      </Grid>
                    ))}
              </Grid>
  
              {/* Display Strategy Results as Tables */}
              {selectedCategory && strategyResults[selectedCategory].length > 0 && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    {selectedCategory} Strategies Details
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Strategy</TableCell>
                          <TableCell>Strike Price</TableCell>
                          <TableCell>Premium</TableCell>
                          <TableCell>Max Loss</TableCell>
                          <TableCell>Max Profit</TableCell>
                          <TableCell>Breakeven Point</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {strategyResults[selectedCategory].map((strategy, index) => (
                          <TableRow key={index}>
                            <TableCell>{strategy.strategy}</TableCell>
                            <TableCell>
                              {strategy.strikePrice ||
                              strategy.sellStrikePrice ||
                              strategy.buyStrikePrice
                                ? `${strategy.strikePrice || ""} ${
                                    strategy.sellStrikePrice
                                      ? `/${strategy.sellStrikePrice}`
                                      : ""
                                  } ${strategy.buyStrikePrice ? `/${strategy.buyStrikePrice}` : ""}`
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {strategy.premium
                                ? formatNumber(strategy.premium)
                                : strategy.totalPremium
                                ? formatNumber(strategy.totalPremium)
                                : strategy.netPremium
                                ? formatNumber(strategy.netPremium)
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {strategy.maxLoss
                                ? formatNumber(strategy.maxLoss)
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {strategy.maxProfit
                                ? formatNumber(strategy.maxProfit) || strategy.maxProfit
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {strategy.breakeven
                                ? formatNumber(strategy.breakeven)
                                : strategy.breakevenUp
                                ? `${formatNumber(strategy.breakevenUp)} / ${formatNumber(
                                    strategy.breakevenDown
                                  )}`
                                : "-"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
  
              {/* Display Nearest Strike Price Information */}
              {nearestStrikePrice && (
                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <Typography variant="subtitle1">
                    <strong>Nearest Strike Price (ATM):</strong> {nearestStrikePrice}
                  </Typography>
                  {indexData?.Ltp && (
                    <Typography variant="body2">
                      Current NIFTY 50 LTP: {formatNumber(indexData.Ltp)}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
  
        {/* Notification Snackbar at Bottom Center */}
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </>
    );
  };
  
  export default OptionStrategies;
  