
import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure Axios is installed via npm/yarn
import {
  Box,
  Tabs,
  Tab,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  IconButton,
  Tooltip,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import HomePage from "./HomePage"; // Ensure this component exists and is correctly implemented

// TabPanel Component to handle tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`bulk-symbol-list-tabpanel-${index}`}
      aria-labelledby={`bulk-symbol-list-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 2, sm: 3 } }}>{children}</Box>}
    </div>
  );
}

// Accessibility props for tabs
function a11yProps(index) {
  return {
    id: `bulk-symbol-list-tab-${index}`,
    "aria-controls": `bulk-symbol-list-tabpanel-${index}`,
  };
}

const BulkSymbolAdd = () => {
  const [tabValue, setTabValue] = useState(0);
  const [symbolLists, setSymbolLists] = useState([
    { id: 1, symbols: [], selected: [] },
    { id: 2, symbols: [], selected: [] },
    { id: 3, symbols: [], selected: [] },
    { id: 4, symbols: [], selected: [] },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [confirmDeleteAll, setConfirmDeleteAll] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For upload progress

  // Function to close the snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Fetch symbols when the selected tab changes
  useEffect(() => {
    fetchSymbols(tabValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  // Function to fetch symbols from the backend
  const fetchSymbols = async (tabIndex) => {
    const listId = tabIndex + 1; // Lists are 1-indexed
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.arasu.online/api/BulkSymbols?listId=${listId}`
      );
      setSymbolLists((prevLists) => {
        const newLists = [...prevLists];
        newLists[tabIndex].symbols = response.data; // Ensure backend returns an array of symbols
        newLists[tabIndex].selected = [];
        return newLists;
      });
    } catch (error) {
      console.error("Error fetching symbols:", error);
      setSnackbar({
        open: true,
        message: "Failed to fetch symbols.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle tab change
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
    
    } else {
      setSnackbar({
        open: true,
        message: "Please select a valid CSV file.",
        severity: "warning",
      });
      event.target.value = null;
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedFile) {
      setSnackbar({
        open: true,
        message: "No file selected.",
        severity: "warning",
      });
      return;
    }

    const listId = tabValue + 1;
    const formData = new FormData();
    formData.append("file", selectedFile);

  
    setLoading(true);
    setUploadProgress(0); // Reset upload progress

    try {
      const response = await axios.post(
        `https://api.arasu.online/api/BulkSymbols/upload?listId=${listId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
           
            setUploadProgress(percentCompleted);
          },
        }
      );

      console.log("response data   " + response.data);
      setSnackbar({
        open: true,
        message: "File uploaded successfully.",
        severity: "success",
      });
      setSelectedFile(null);
      fetchSymbols(tabValue); // Refresh the symbol list after upload
    } catch (error) {
      console.error("Error uploading file:", error);
      setSnackbar({
        open: true,
        message: "Failed to upload file.",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setUploadProgress(0); // Reset progress
    }
  };

  // Toggle selection of a symbol
  const handleToggle = (id) => {
    setSymbolLists((prevLists) => {
      const newLists = [...prevLists];
      const currentList = { ...newLists[tabValue] };
      if (currentList.selected.includes(id)) {
        currentList.selected = currentList.selected.filter(
          (item) => item !== id
        );
      } else {
        currentList.selected = [...currentList.selected, id];
      }
      newLists[tabValue] = currentList;
      return newLists;
    });
  };

  // Handle deletion of selected symbols
  const handleDeleteSelected = async () => {
    const listId = tabValue + 1;
    const selectedIds = symbolLists[tabValue].selected;

    if (selectedIds.length === 0) return;

    setLoading(true);
    try {
      const response = await axios.delete(
        `https://api.arasu.online/api/BulkSymbols?listId=${listId}`,
        {
          data: { Ids: selectedIds },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Delete Selected Response:", response.data);
      setSnackbar({
        open: true,
        message: "Selected symbols deleted.",
        severity: "success",
      });
      fetchSymbols(tabValue);
    } catch (error) {
      console.error("Error deleting selected symbols:", error);
      if (error.response && error.response.data) {
        setSnackbar({
          open: true,
          message: `Failed to delete selected symbols: ${error.response.data}`,
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Failed to delete selected symbols.",
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle deletion of all symbols
  const handleDeleteAll = () => {
    setConfirmDeleteAll(true);
  };

  // Confirm deletion of all symbols
  const confirmDeleteAllSymbols = async () => {
    const listId = tabValue + 1;

    setConfirmDeleteAll(false);
    setLoading(true);
    try {
      const response = await axios.delete(
        `https://api.arasu.online/api/BulkSymbols/?listId=${listId}`,
        {
          data: { All: true }, // Only All is required
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Delete All Response:", response.data);
      setSnackbar({
        open: true,
        message: "All symbols deleted.",
        severity: "success",
      });
      fetchSymbols(tabValue);
    } catch (error) {
      console.error("Error deleting all symbols:", error.response);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "Failed to delete all symbols.";
        setSnackbar({
          open: true,
          message: `Failed to delete all symbols: ${errorMessage}`,
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Failed to delete all symbols.",
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle select all checkbox
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    setSymbolLists((prevLists) => {
      const newLists = [...prevLists];
      const currentList = { ...newLists[tabValue] };
      if (isChecked) {
        currentList.selected = currentList.symbols.map((symbol) => symbol.id);
      } else {
        currentList.selected = [];
      }
      newLists[tabValue] = currentList;
      return newLists;
    });
  };

  return (
    <>
      <HomePage /> {/* Ensure this component is implemented correctly */}
      <Box >
        <Paper
          
          sx={{ padding: { xs: 1, sm: 1 } }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="bulk symbol list tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                backgroundColor: "#a6fc5b",
                color: "#fafafa",
                "& .MuiTab-root": {
                  color: "#000000",
                  textTransform: "none",
                  fontWeight: 600,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: "#fafafa",
                  backgroundColor: "#71e809",
                  borderRadius: 1,
                },
              }}
            >
              <Tab label="SymbolList 1" {...a11yProps(0)} />
              <Tab label="SymbolList 2" {...a11yProps(1)} />
              <Tab label="SymbolList 3" {...a11yProps(2)} />
              <Tab label="SymbolList 4" {...a11yProps(3)} />
            </Tabs>
          </Box>

          {symbolLists.map((list, index) => (
            <TabPanel value={tabValue} index={index} key={list.id}>
              <Typography variant="body1" component="p" sx={{marginBottom:"10px"}}>
                Ensure Excel file data prefix{" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  NSE:
                </Typography>{" "}
                and suffix{" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  "-INDEX", "-EQ"
                </Typography>
                .
              </Typography>

              <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
                {/* File Input and Upload Button */}
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<UploadIcon />}
                  sx={{
                    mr: 2,
                    backgroundColor: "secondary.main",
                    "&:hover": { backgroundColor: "secondary.dark" },
                  }}
                >
                  Select CSV File
                  <input
                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpload}
                  disabled={!selectedFile || loading}
                  startIcon={<UploadIcon />}
                >
                  Upload
                </Button>
                {selectedFile && (
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {selectedFile.name}
                  </Typography>
                )}
              </Box>

              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <CircularProgress />
                </Box>
              ) : list.symbols.length > 0 ? (
                <>
                  <Table sx={{ backgroundColor: "background.paper" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={
                              list.selected.length > 0 &&
                              list.selected.length < list.symbols.length
                            }
                            checked={
                              list.symbols.length > 0 &&
                              list.selected.length === list.symbols.length
                            }
                            onChange={handleSelectAll}
                            inputProps={{
                              "aria-label": "select all bulk symbols",
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Symbol
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.symbols.map((symbol) => (
                        <TableRow key={symbol.id} hover>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={list.selected.includes(symbol.id)}
                              onChange={() => handleToggle(symbol.id)}
                              inputProps={{
                                "aria-labelledby": `bulk-symbol-checkbox-${symbol.id}`,
                              }}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {symbol.name}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Delete Symbol">
                              <IconButton
                                color="error"
                                onClick={async () => {
                                  setLoading(true);
                                  try {
                                    await axios.delete(
                                      `https://api.arasu.online/api/BulkSymbols?listId=${
                                        tabValue + 1
                                      }`,
                                      {
                                        data: { Ids: [symbol.id] },
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                      }
                                    );
                                    setSnackbar({
                                      open: true,
                                      message: "Symbol deleted.",
                                      severity: "success",
                                    });
                                    fetchSymbols(tabValue);
                                  } catch (error) {
                                    console.error(
                                      "Error deleting symbol:",
                                      error
                                    );
                                    if (error.response && error.response.data) {
                                      setSnackbar({
                                        open: true,
                                        message: `Failed to delete symbol: ${error.response.data}`,
                                        severity: "error",
                                      });
                                    } else {
                                      setSnackbar({
                                        open: true,
                                        message: "Failed to delete symbol.",
                                        severity: "error",
                                      });
                                    }
                                  } finally {
                                    setLoading(false);
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  {/* Upload Progress Indicator */}
                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <CircularProgress
                        variant="determinate"
                        value={uploadProgress}
                      />
                      <Typography variant="body2" sx={{ ml: 2 }}>
                        {uploadProgress}%
                      </Typography>
                    </Box>
                  )}

                  {/* Action Buttons */}
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleDeleteSelected}
                      disabled={list.selected.length === 0 || loading}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Selected
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleDeleteAll}
                      disabled={loading}
                      startIcon={<DeleteIcon />}
                    >
                      Delete All
                    </Button>
                  </Box>
                </>
              ) : (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1" color="textSecondary">
                    No symbols uploaded. Click "Select CSV File" to add symbols.
                  </Typography>
                </Box>
              )}
            </TabPanel>
          ))}
        </Paper>
      </Box>
      {/* Confirmation Dialog for Delete All */}
      <Dialog
        open={confirmDeleteAll}
        onClose={() => setConfirmDeleteAll(false)}
        aria-labelledby="delete-all-dialog-title"
        aria-describedby="delete-all-dialog-description"
      >
        <DialogTitle id="delete-all-dialog-title">
          Delete All Symbols
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-all-dialog-description">
            Are you sure you want to delete all symbols in this list? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteAll(false)}>Cancel</Button>
          <Button onClick={confirmDeleteAllSymbols} color="error" autoFocus>
            Delete All
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BulkSymbolAdd;
