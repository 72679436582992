import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Container, Alert } from "@mui/material";

const RefreshToken = () => {
  const hasProcessedAuth = useRef(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const processAuth = async () => {
      if (hasProcessedAuth.current) {
        return;
      } else {
        hasProcessedAuth.current = true;

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const authCodeParam = url.searchParams.get("auth_code");

        const trimmedAuthCode = authCodeParam ? authCodeParam.trim() : "";
        if (!trimmedAuthCode) {
          console.error("Authentication code missing.");
          setError("Authentication code is missing. Please try again.");
          setLoading(false);
          return;
        }

        // Remove auth parameters from URL
        window.history.replaceState({}, document.title, "/main");

        try {
          const response = await axios.post(
            "https://api.arasu.online/api/Auth/ReceiveAuthCode",
            {
              authCode: trimmedAuthCode,
            }
          );

          if (response.status === 200) {
            navigate("/main");
          } else {
            setError("Failed to authenticate. Please try again.");
          }
        } catch (error) {
          console.error("Error during authentication:", error.response?.data || error.message);
          setError("An error occurred during authentication. Please try again.");
        } finally {
          setLoading(false);
        }
      }
    };

    processAuth();
  }, [navigate]);

  return (
    <Container className="refresh-token-container" style={{ textAlign: "center", marginTop: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Refresh Token
      </Typography>

      {loading ? (
        <div style={{ marginTop: "20px" }}>
          <CircularProgress />
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            Processing authentication...
          </Typography>
        </div>
      ) : error ? (
        <Alert severity="error" style={{ marginTop: "20px" }}>
          {error}
        </Alert>
      ) : (
        <Typography variant="body1" style={{ marginTop: "20px" }}>
          Redirecting...
        </Typography>
      )}
    </Container>
  );
};

export default RefreshToken;
