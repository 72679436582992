import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import LoginPage from "./pages/LoginPage";
import ViewData from "./pages/Viewdata";
import Main from "./pages/Main";
import FifteenMinData from "./pages/fifteenMinData";
import OneMinDataPage from "./pages/oneMinData";
import Watchlist from "./pages/watchlist";
import ErrorBoundary from "./pages/ErrorBoundary";
import OptionsChain from "./pages/OptionsChain";
import OptionChainLive from "./pages/OptionChainLive";
import Nifty50Contribution from "./pages/Nifty50Contribution";
import OptionStrategies from "./pages/OptionsStrategies";
import Settings from "./pages/Settings";
import ProtectedRoute from "./ProtectedRoute";
import Unauthorized from "./Unauthorized";
import WatchlistOneMinuteData from "./pages/WatchlistOneMinuteData";
import RefreshToken from "./pages/RefreshToken";

function App() {
  return (
    <div className="App">
      <Router>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/main" element= { <ProtectedRoute> <Main /> </ProtectedRoute> } />
            <Route path="/LastData" element={<ProtectedRoute> <ViewData /> </ProtectedRoute>} />
            <Route path="/fifteen-min-data" element={<FifteenMinData /> } />
            <Route path="/one-min-data" element={<OneMinDataPage />} />
            <Route path="/watchlist" element={<ProtectedRoute> <Watchlist /> </ProtectedRoute>} />
            <Route path="/options-chain" element={<ProtectedRoute> <OptionsChain /> </ProtectedRoute>} />
            <Route path="/options-chain-live" element={<ProtectedRoute> <OptionChainLive /> </ProtectedRoute>} />
            <Route path="/nifty50-cont" element={<ProtectedRoute> <Nifty50Contribution /> </ProtectedRoute>} />
            <Route path="/Option-strategies" element={<ProtectedRoute> <OptionStrategies /> </ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute> <Settings /> </ProtectedRoute>} />
            <Route path="/watchlist-one-min-data" element={<ProtectedRoute> <WatchlistOneMinuteData /> </ProtectedRoute>} />

            <Route path="/refreshToken" element={<RefreshToken/>}/>

            <Route path="/unauthorized" element={<Unauthorized />} />
          </Routes>
        </ErrorBoundary>
      </Router>
    </div>
  );
}

export default App;

