// src/pages/UserDataEditModel.js

import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import {  useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const UserDataEditModel = ({
  open,
  handleClose,
  formData,
  handleChange,
  handleSubmit,
  errors, // Optional: For form validation errors
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Responsive style for the modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "80%", // Adjust width based on device
    maxWidth: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: isMobile ? 2 : 4, // Adjust padding based on device
    borderRadius: 2,
    maxHeight: "90vh",
    overflowY: "auto",
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="edit-user-modal">
      <Box sx={style}>
        {/* Modal Header */}
        <Typography
          id="edit-user-modal"
          variant={isMobile ? "h6" : "h5"}
          component="h2"
          gutterBottom
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          Edit User Details
        </Typography>

        {/* Grid Container for Form Fields */}
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="User Id"
              name="UserId"
              variant="outlined"
              value={formData.UserId}
              onChange={handleChange}
              error={Boolean(errors?.UserId)}
              helperText={errors?.UserId}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Client Id"
              name="ClientId"
              variant="outlined"
              value={formData.ClientId}
              onChange={handleChange}
              error={Boolean(errors?.ClientId)}
              helperText={errors?.ClientId}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Secret Key"
              name="Secretkey"
              variant="outlined"
              multiline
              rows={isMobile ? 3 : 2}
              value={formData.Secretkey}
              onChange={handleChange}
              error={Boolean(errors?.Secretkey)}
              helperText={errors?.Secretkey}
              InputProps={{
                sx: {
                  "& textarea": {
                    overflow: "auto", // Enable scrollbar when content exceeds
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Pin"
              name="Pin"
              variant="outlined"
              multiline
              rows={isMobile ? 3 : 2}
              value={formData.Pin}
              onChange={handleChange}
              error={Boolean(errors?.Pin)}
              helperText={errors?.Pin}
              InputProps={{
                sx: {
                  "& textarea": {
                    overflow: "auto", // Enable scrollbar when content exceeds
                  },
                },
              }}
            />
          </Grid>

          {/* Third Row */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Redirect Uri"
              name="RedirectUri"
              variant="outlined"
              multiline
              rows={isMobile ? 3 : 2}
              value={formData.RedirectUri}
              onChange={handleChange}
              error={Boolean(errors?.RedirectUri)}
              helperText={errors?.RedirectUri}
              InputProps={{
                sx: {
                  "& textarea": {
                    overflow: "auto", // Enable scrollbar when content exceeds
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Refresh Token Url"
              name="RefreshTokenUrl"
              variant="outlined"
              value={formData.RefreshTokenUrl}
              onChange={handleChange}
              error={Boolean(errors?.RefreshTokenUrl)}
              helperText={errors?.RefreshTokenUrl}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* Fourth Row */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="App Hash Id"
              name="AppHashId"
              variant="outlined"
              multiline
              rows={isMobile ? 3 : 2}
              value={formData.AppHashId}
              onChange={handleChange}
              error={Boolean(errors?.AppHashId)}
              helperText={errors?.AppHashId}
              InputProps={{
                sx: {
                  "& textarea": {
                    overflow: "auto", // Enable scrollbar when content exceeds
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Refresh Token"
              name="RefreshToken"
              variant="outlined"
              multiline
              rows={isMobile ? 4 : 3} // Increase rows for better readability
              value={formData.RefreshToken}
              onChange={handleChange}
              error={Boolean(errors?.RefreshToken)}
              helperText={errors?.RefreshToken}
              InputProps={{
                sx: {
                  "& textarea": {
                    overflow: "auto", // Enable scrollbar when content exceeds
                  },
                },
              }}
            />
          </Grid>

          {/* Fifth Row */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Access Token"
              name="AccessToken"
              variant="outlined"
              multiline
              rows={isMobile ? 4 : 3} // Increase rows for better readability
              value={formData.AccessToken}
              onChange={handleChange}
              error={Boolean(errors?.AccessToken)}
              helperText={errors?.AccessToken}
              InputProps={{
                sx: {
                  "& textarea": {
                    overflow: "auto", // Enable scrollbar when content exceeds
                  },
                },
              }}
            />
          </Grid>

          {/* Buttons Row */}
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                mt: 2,
                backgroundColor: "#84cf0e",
                "&:hover": {
                  backgroundColor: "#6bb30c",
                },
                height: 48, // Consistent height for better touch targets
              }}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleClose}
              sx={{
                mt: 2,
                backgroundColor: "#f0a918",
                "&:hover": {
                  backgroundColor: "#d68e14",
                },
                height: 48, // Consistent height for better touch targets
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UserDataEditModel;

// Styled components (if needed)
// In this case, the component primarily uses MUI's built-in components and styling via `sx` prop.
// However, if you prefer to use styled components for consistency or additional styling, you can add them here.
