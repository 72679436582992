// src/pages/GeneralSettings.js
import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Importing icons for better visual representation
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import HomePage from "./HomePage";

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.arasu.online";

// Styled component for consistent card styling
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 600,
  margin: "auto",
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
}));

// Styled CardHeader with dynamic background based on props
const StyledCardHeader = styled(CardHeader, {
  shouldForwardProp: (prop) => prop !== "bgcolor",
})(({ theme, bgcolor }) => ({
  backgroundColor: theme.palette[bgcolor]?.light || theme.palette.primary.light,
  color: theme.palette[bgcolor]?.contrastText || theme.palette.primary.contrastText,
  "& .MuiCardHeader-title": {
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

const GeneralSettings = () => {
  // === 1. Declare All State Variables ===

  // Unified Snackbar State
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // 'success' | 'error' | 'warning' | 'info'
  });

  // Loading states for different actions
  const [loadingStates, setLoadingStates] = useState({
    downloadSymbol: false,
    deleteSymbol: false,
    deleteAllViewData: false,
    refreshToken: false,
    symbolSubscription: false,
    symbolDataDownload: false,
    stockDataDownload: false,
  });

  // State variables for scheduled times (consistent camelCase)
  const [symbolSubscriptionHour, setSymbolSubscriptionHour] = useState("");
  const [symbolDataDownloadHour, setSymbolDataDownloadHour] = useState("");
  const [stockDataDownloadHour, setStockDataDownloadHour] = useState("");

  const [symbolSubscriptionMin, setSymbolSubscriptionMin] = useState("");
  const [symbolDataDownloadMin, setSymbolDataDownloadMin] = useState("");
  const [stockDataDownloadMin, setStockDataDownloadMin] = useState("");

  // === 2. Define Helper Functions ===

  // Function to extract error messages
  const getErrorMessage = (error) => {
    if (error.response) {
      console.error("Error Response:", error.response);
      console.error("Response Data:", error.response.data);
      return error.response.data.message || JSON.stringify(error.response.data);
    } else if (error.request) {
      console.error("No response received:", error.request);
      return "No response from server.";
    } else {
      console.error("Error setting up request:", error.message);
      return "An unexpected error occurred.";
    }
  };

  // Handler to close the Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // Generic handler to update loading states
  const setLoading = (key, value) => {
    setLoadingStates((prev) => ({ ...prev, [key]: value }));
  };

  // Helper function to get auth headers if required
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAuthHeaders = useCallback(() => {
    const token = localStorage.getItem("accessToken"); // Adjust the key as per your implementation
    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
    return {};
  });

  // === 3. Define getAutoScheduledTime Function ===

  // Unified function to fetch scheduled times
  const getAutoScheduledTime = useCallback(async (type) => {
    let endpoint = "";
    let setHour, setMinute;

    switch (type) {
      case "SymbolSubscription":
        endpoint = "/api/SymbolSubscription/get-time";
        setHour = setSymbolSubscriptionHour;
        setMinute = setSymbolSubscriptionMin;
        break;
      case "SymbolDataDownload":
        endpoint = "/api/SymbolDataDownload/get-time";
        setHour = setSymbolDataDownloadHour;
        setMinute = setSymbolDataDownloadMin;
        break;
      case "StockDataDownload":
        endpoint = "/api/StockDataSetting/get-time";
        setHour = setStockDataDownloadHour;
        setMinute = setStockDataDownloadMin;
        break;
      default:
        return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}${endpoint}`, {
        headers: {
          ...getAuthHeaders(),
          "Content-Type": "application/json",
        },
      });
      const { scheduledHour, scheduledMinute } = response.data;
     
      setHour(scheduledHour);
      setMinute(scheduledMinute);
    } catch (error) {
      console.error(`Axios error while fetching ${type}:`, error.message);
      // Optionally, display a snackbar message for fetch errors
      setSnackbar({
        open: true,
        message: `Failed to fetch ${type} scheduled time.`,
        severity: "error",
      });
    }
  }, [
    getAuthHeaders,
    setSymbolDataDownloadHour,
    setSymbolDataDownloadMin,
    setSymbolSubscriptionHour,
    setSymbolSubscriptionMin,
    setStockDataDownloadHour,
    setStockDataDownloadMin,
  ]);

  // === 4. Fetch Initial Scheduled Times on Component Mount ===

  useEffect(() => {
    getAutoScheduledTime("SymbolSubscription");
    getAutoScheduledTime("SymbolDataDownload");
    getAutoScheduledTime("StockDataDownload");
  }, [getAutoScheduledTime]);

  // === 5. Define Action Handlers ===

  const handleDownloadSymbol = async () => {
    setSnackbar({
      open: true,
      message: "Downloading symbols...",
      severity: "info",
    });
    setLoading("downloadSymbol", true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/Symbol/all-symbol-download`,
        {},
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Download Symbols Response:", response.data);
      setSnackbar({
        open: true,
        message: "Symbols downloaded successfully.",
        severity: "success",
      });
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbar({ open: true, message, severity: "error" });
    } finally {
      setLoading("downloadSymbol", false);
    }
  };

  const handleDeleteSymbol = async () => {
    setSnackbar({
      open: true,
      message: "Deleting symbols...",
      severity: "info",
    });
    setLoading("deleteSymbol", true);
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/Symbol/all-symbol-delete`,
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Delete Symbols Response:", response.data);
      setSnackbar({
        open: true,
        message: "Symbols deleted successfully.",
        severity: "success",
      });
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbar({ open: true, message, severity: "error" });
    } finally {
      setLoading("deleteSymbol", false);
    }
  };

  const handleDeleteAllViewData = async () => {
    setSnackbar({
      open: true,
      message: "Deleting all view data...",
      severity: "info",
    });
    setLoading("deleteAllViewData", true);
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/Data/DeleteAll`,
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Delete All View Data Response:", response.data);
      setSnackbar({
        open: true,
        message: "All view data deleted successfully.",
        severity: "success",
      });
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbar({ open: true, message, severity: "error" });
    } finally {
      setLoading("deleteAllViewData", false);
    }
  };

  const handleRefreshToken = async () => {
    setSnackbar({
      open: true,
      message: "Refreshing token...",
      severity: "info",
    });
    setLoading("refreshToken", true);
    try {
      const userId = localStorage.getItem("userId"); // Replace with dynamic value if necessary
      const response = await axios.post(
        `${API_BASE_URL}/api/Auth/refresh-token`,
        { userId },
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
      const newAccessToken = response.data.access_token;
    
      setSnackbar({
        open: true,
        message: "Token refreshed successfully.",
        severity: "success",
      });
      // Update token in local storage or context
      localStorage.setItem("accessToken", newAccessToken);
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbar({ open: true, message, severity: "error" });
    } finally {
      setLoading("refreshToken", false);
    }
  };

  // === 6. Define SettingsForm Component ===

  // Reusable Settings Form Component
  const SettingsForm = ({
    title,
    description,
    scheduledHour,
    scheduledMinute,
    onSubmit,
    loading,
    bgcolor = "primary",
  }) => {
    const [hour, setHour] = useState(scheduledHour);
    const [minute, setMinute] = useState(scheduledMinute);

    const handleSubmit = async () => {
      // Basic validation
      if (hour === "" || minute === "") {
        setSnackbar({
          open: true,
          message: "All input fields are required.",
          severity: "warning",
        });
        return;
      }

      // Additional validation
      const hourNum = parseInt(hour, 10);
      const minuteNum = parseInt(minute, 10);
      if (
        isNaN(hourNum) ||
        isNaN(minuteNum) ||
        hourNum < 0 ||
        hourNum > 23 ||
        minuteNum < 0 ||
        minuteNum > 59
      ) {
        setSnackbar({
          open: true,
          message: "Please enter valid hour and minute values.",
          severity: "warning",
        });
        return;
      }

      // Prepare payload with camelCase property names
      const payload = {
        scheduledHour: hourNum,
        scheduledMinute: minuteNum,
        // Add any additional required fields here
        // Example: userId: localStorage.getItem("userId"),
      };

    

      // Call the passed onSubmit handler with payload
      await onSubmit(payload);

      // Optionally, reset fields upon success
      // setHour("");
      // setMinute("");
    };

    useEffect(() => {
      setHour(scheduledHour);
      setMinute(scheduledMinute);
    }, [scheduledHour, scheduledMinute]);

    return (
      <StyledCard>
        <StyledCardHeader title={title} bgcolor={bgcolor} />
        <CardContent>
          <Typography variant="body1" align="center" gutterBottom>
            {description}
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <TextField
                label="Hour"
                type="number"
                variant="outlined"
                fullWidth
                value={hour}
                onChange={(e) => setHour(e.target.value)}
                required
                InputProps={{ inputProps: { min: 0, max: 23 } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Minute"
                type="number"
                variant="outlined"
                fullWidth
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
                required
                InputProps={{ inputProps: { min: 0, max: 59 } }}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Horizontal alignment
              alignItems: "center", // Vertical alignment
            }}
          >
            <Button
              variant="contained"
              color="success"
              size="medium"
              startIcon={<RefreshIcon />}
              onClick={handleSubmit}
              disabled={loading}
              sx={{
                marginTop: "15px",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.success.dark,
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Update"
              )}
            </Button>
          </Box>
        </CardContent>
      </StyledCard>
    );
  };

  // === 7. Define Handlers for Settings Forms ===

  const handleSymbolSubscription = async (payload) => {
    setSnackbar({
      open: true,
      message: "Updating Symbol Subscription...",
      severity: "info",
    });
    setLoading("symbolSubscription", true);
    try {
      // Changed from PUT to POST
      const response = await axios.post(
        `${API_BASE_URL}/api/SymbolSubscription/update-time`,
        payload,
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
    
      setSnackbar({
        open: true,
        message:
          response.data?.message || "Symbol Subscription settings updated successfully!",
        severity: "success",
      });
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbar({ open: true, message, severity: "error" });
    } finally {
      setLoading("symbolSubscription", false);
      getAutoScheduledTime("SymbolSubscription");
    }
  };

  const handleSymbolDataDownloadTime = async (payload) => {
    setSnackbar({
      open: true,
      message: "Updating Symbol Data Download Time...",
      severity: "info",
    });
    setLoading("symbolDataDownload", true);
    try {
      // Changed from PUT to POST
      const response = await axios.post(
        `${API_BASE_URL}/api/SymbolDataDownload/update-time`,
        payload,
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
     
      setSnackbar({
        open: true,
        message:
          response.data?.message || "Symbol Data Download Time updated successfully!",
        severity: "success",
      });
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbar({ open: true, message, severity: "error" });
    } finally {
      setLoading("symbolDataDownload", false);
      getAutoScheduledTime("SymbolDataDownload");
    }
  };

  const handleStockDataDownloadTime = async (payload) => {
    setSnackbar({
      open: true,
      message: "Updating Stock Data Download Time...",
      severity: "info",
    });
    setLoading("stockDataDownload", true);
    try {
      // Changed from PUT to POST
      const response = await axios.post(
        `${API_BASE_URL}/api/StockDataSetting/update-time`,
        payload,
        {
          headers: {
            ...getAuthHeaders(),
            "Content-Type": "application/json",
          },
        }
      );
     
      setSnackbar({
        open: true,
        message:
          response.data?.message || "Stock Data Download Time updated successfully!",
        severity: "success",
      });
    } catch (error) {
      const message = getErrorMessage(error);
      setSnackbar({ open: true, message, severity: "error" });
    } finally {
      setLoading("stockDataDownload", false);
      getAutoScheduledTime("StockDataDownload");
    }
  };

  // === 8. Render the Component ===

  return (
    <>
      {/* Remove HomePage if not needed or ensure it's correctly implemented */}
      <HomePage />

      {/* Container for all settings cards */}
      <Box sx={{ padding: 2 }}>
        <Stack spacing={4}>
          {/* Symbol Data Service Card */}
          <StyledCard>
            <StyledCardHeader title="Symbol Data Service" bgcolor="primary" />
            <CardContent>
              <Typography variant="body1" align="center" gutterBottom>
                Manage your symbol data by downloading or deleting symbols.
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadSymbol}
                    disabled={loadingStates.downloadSymbol}
                    sx={{
                      minWidth: 160,
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.dark,
                      },
                    }}
                  >
                    {loadingStates.downloadSymbol ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Download Symbols"
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteSymbol}
                    disabled={loadingStates.deleteSymbol}
                    sx={{
                      minWidth: 160,
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.secondary.dark,
                      },
                    }}
                  >
                    {loadingStates.deleteSymbol ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Delete Symbols"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          {/* Delete All View Data Card */}
          <StyledCard>
            <StyledCardHeader title="Delete All View Data" bgcolor="error" />
            <CardContent>
              <Typography variant="body1" align="center" gutterBottom>
                Manage your view data by deleting all view records.
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="error"
                    size="medium"
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteAllViewData}
                    disabled={loadingStates.deleteAllViewData}
                    sx={{
                      minWidth: 200,
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.error.light,
                      },
                    }}
                  >
                    {loadingStates.deleteAllViewData ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Delete All View Data"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          {/* Refresh Token Card */}
          <StyledCard>
            <StyledCardHeader title="Refresh Token" bgcolor="success" />
            <CardContent>
              <Typography variant="body1" align="center" gutterBottom>
                Refresh your authentication tokens to maintain secure access.
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    size="medium"
                    startIcon={<RefreshIcon />}
                    onClick={handleRefreshToken}
                    disabled={loadingStates.refreshToken}
                    sx={{
                      minWidth: 180,
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.success.dark,
                      },
                    }}
                  >
                    {loadingStates.refreshToken ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Refresh Token"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          {/* Symbol Subscription Settings */}
          <SettingsForm
            title="Auto Symbol Subscription Time"
            description="Update the scheduled time for symbol subscriptions."
            scheduledHour={symbolSubscriptionHour}
            scheduledMinute={symbolSubscriptionMin}
            onSubmit={handleSymbolSubscription}
            loading={loadingStates.symbolSubscription}
            bgcolor="warning"
          />

          {/* Symbol Data Download Time Update */}
          <SettingsForm
            title="Auto Symbol Data Download Time"
            description="Configure the time for symbol data downloads."
            scheduledHour={symbolDataDownloadHour}
            scheduledMinute={symbolDataDownloadMin}
            onSubmit={handleSymbolDataDownloadTime}
            loading={loadingStates.symbolDataDownload}
            bgcolor="info"
          />

          {/* Stock Data Download Time Update */}
          <SettingsForm
            title="Auto Stock Data Download Time"
            description="Configure the time for stock data downloads."
            scheduledHour={stockDataDownloadHour}
            scheduledMinute={stockDataDownloadMin}
            onSubmit={handleStockDataDownloadTime}
            loading={loadingStates.stockDataDownload}
            bgcolor="secondary"
          />
        </Stack>
      </Box>

      {/* Unified Snackbar for Feedback Messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GeneralSettings;
