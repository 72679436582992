import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UpdateIcon from "@mui/icons-material/Update";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import HomePage from "./HomePage";
import useMediaQuery from "@mui/material/useMediaQuery";
import User from "@mui/icons-material/AccountBox";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Optional: Import CloseIcon

// Import your new components
import GeneralSettings from "./generalSettings";
import BulkSymbolAdd from "./BulkSymbolAdd";
import SubscribeSymbol from "./SubscribeSymbols";
import StockDatas from "./StockDatas";
import RegisterApiDetails from "./RegisterDetails";

const drawerWidth = 240;

// Styled Drawer component without filtering the 'open' prop
const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    marginTop: "65px", // Consistent top margin
    width: drawerWidth,
    backgroundColor: theme.palette.background.default,
  },
}));

export default function Settings() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // State to track selected menu item
  const [selectedMenu, setSelectedMenu] = React.useState("Mac Datas");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // Menu configuration array
  const menuItems = [
    {
      text: "Mac Datas",
      icon: <DashboardIcon />,
      component: <StockDatas />,
    },
    {
      text: "Bulk Symbol Add",
      icon: <AddBoxIcon />,
      component: <BulkSymbolAdd />,
    },
    {
      text: "Subscribe Symbol",
      icon: <SubscriptionsIcon />,
      component: <SubscribeSymbol />,
    },
    {
      text: "General Settings",
      icon: <UpdateIcon />,
      component: <GeneralSettings />,
    },
    {
      text: "User Apidetails",
      icon: <User />,
      component: <RegisterApiDetails />,
    },
  ];

  // Function to render the selected component
  const renderContent = () => {
    const menu = menuItems.find((item) => item.text === selectedMenu);
    return menu ? menu.component : <HomePage />;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />


      {/* Menu Icon Button: Visible only on Mobile and when Drawer is closed */}
      {isMobile && !mobileOpen && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            position: "fixed",
            top: 60, // Adjusted for better placement
            left: 16,
            zIndex: theme.zIndex.drawer + 2, // Ensure it's above the drawer
            backgroundColor: "background.paper", // Optional: adds a background for visibility
            '&:hover': {
              backgroundColor: "grey.300", // Optional: hover effect
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Optional: Close Icon Button: Visible only on Mobile and when Drawer is open */}
      {isMobile && mobileOpen && (
        <IconButton
          color="inherit"
          aria-label="close drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            position: "fixed",
            top: 16, // Adjusted for better placement
            left: 16,
            zIndex: theme.zIndex.drawer + 2, // Ensure it's above the drawer
            backgroundColor: "background.paper", // Optional: adds a background for visibility
            '&:hover': {
              backgroundColor: "grey.300", // Optional: hover effect
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      {/* Drawer Component */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <List>
          {/* Menu Items */}
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => {
                  setSelectedMenu(item.text);
                  if (isMobile) {
                    setMobileOpen(false); // Close drawer on mobile after selection
                  }
                }}
                sx={{
                  minHeight: 48,
                  px: 2.5,
                  backgroundColor:
                    selectedMenu === item.text
                      ? "rgba(0, 0, 0, 0.08)"
                      : "inherit",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 2,
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3}}>
        {renderContent()}
      </Box>
    </Box>
  );
}
