import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableSortLabel,
  Grid,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import HomePage from './HomePage';

// Define a styled TableCell for the header to add background color and other styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const WatchlistOneMinuteData = () => {
  const [watchlist, setWatchlist] = useState([]); // Holds the watchlist data
  const [data, setData] = useState([]); // Holds the fetched stock data
  const [loading, setLoading] = useState(true); // Indicates if data is being loaded
  const [error, setError] = useState(null); // Holds any error messages
  const [selectedSymbol, setSelectedSymbol] = useState(''); // Currently selected symbol

  // Sorting state
  const [order, setOrder] = useState('desc'); // 'asc' or 'desc'
  const [orderBy, setOrderBy] = useState('timestamp'); // Column to sort by

  // Fetch watchlist and set default selected symbol
  useEffect(() => {
    const fetchWatchlist = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token'); // Assuming token is stored here

        if (!userId) {
          throw new Error('User ID or token not found in localStorage.');
        }

        const response = await axios.get(
          'https://api.arasu.online/api/Watchlist/get_watchlist_with_ltp',
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include JWT token if required
            },
            params: { userId: userId },
          }
        );

        // console.log('Watchlist Response Data:', response.data);

        if (response.data && Array.isArray(response.data.watchlist)) {
          setWatchlist(response.data.watchlist);
          if (response.data.watchlist.length > 0) {
            setSelectedSymbol(response.data.watchlist[0].symTicker); // Set the first symbol as default
          }
        } else {
          setWatchlist([]);
        }
      } catch (err) {
        console.error('Error loading watchlist:', err);
        setError(
          err.response
            ? err.response.data.message
            : err.message || 'Error fetching watchlist. Please try again later.'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchWatchlist();
  }, []); // Empty dependency array to run once on mount

  // Fetch stock data whenever selectedSymbol or order changes
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedSymbol) return; // Do not fetch if no symbol is selected

      try {
        setLoading(true);
        setError(null);

        // Replace the URL below with your actual API endpoint
        const response = await axios.get('https://api.arasu.online/api/stockdata', {
          params: { symbol: selectedSymbol },
        });

        setData(response.data);
      } catch (err) {
        console.error(`Error fetching data for symbol ${selectedSymbol}:`, err);
        setError(
          err.response
            ? err.response.data.message
            : 'Error fetching stock data. Please try again later.'
        );
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedSymbol]); // Run whenever selectedSymbol changes

  // Handle dropdown selection change
  const handleSymbolChange = (event) => {
    setSelectedSymbol(event.target.value);
  };

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Comparator function for sorting
  const comparator = (a, b) => {
    let aValue = a[orderBy];
    let bValue = b[orderBy];

    // If sorting by timestamp, convert to Date objects
    if (orderBy === 'timestamp') {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    }

    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  };

  // Sort data before displaying
  const sortedData = data.slice().sort(comparator);

  return (
    <>
      <HomePage />

      <Box sx={{ padding: 2, marginTop: 4 }}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h6" gutterBottom>
              One Minute Data for Watchlist
            </Typography>
          </Grid>

          <Grid item>
            {/* Dropdown for selecting symbol */}
            <FormControl
              fullWidth
              sx={{
                marginBottom: 2,
                maxWidth: {
                  xs: '100%', // 100% width on extra-small screens
                  sm: '50%',  // 50% width on small screens
                  md: '20%',  // 30% width on medium and larger screens
                },
              }}
            >
              <InputLabel id="symbol-select-label">Select Symbol</InputLabel>
              <Select
                labelId="symbol-select-label"
                id="symbol-select"
                value={selectedSymbol}
                label="Select Symbol"
                onChange={handleSymbolChange}
              >
                {watchlist.map((item) => (
                  <MenuItem key={item.symTicker} value={item.symTicker}>
                    {item.symTicker}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            {/* Display loading spinner, error, or data table */}
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Alert severity="error">{error}</Alert>
            ) : data.length === 0 ? (
              <Alert severity="info">No data available for the selected symbol.</Alert>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 600, // Adjust as needed
                  overflow: 'auto',
                  // For horizontal scroll
                  '& .MuiTable-root': {
                    minWidth: 800, // Ensure the table is wide enough to require horizontal scrolling
                  },
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <TableSortLabel
                          active={orderBy === 'timestamp'}
                          direction={orderBy === 'timestamp' ? order : 'asc'}
                          onClick={() => handleRequestSort('timestamp')}
                          sx={{ color: 'white' }}
                        >
                          Timestamp
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'open'}
                          direction={orderBy === 'open' ? order : 'asc'}
                          onClick={() => handleRequestSort('open')}
                          sx={{ color: 'white' }}
                        >
                          Open
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'high'}
                          direction={orderBy === 'high' ? order : 'asc'}
                          onClick={() => handleRequestSort('high')}
                          sx={{ color: 'white' }}
                        >
                          High
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'low'}
                          direction={orderBy === 'low' ? order : 'asc'}
                          onClick={() => handleRequestSort('low')}
                          sx={{ color: 'white' }}
                        >
                          Low
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'close'}
                          direction={orderBy === 'close' ? order : 'asc'}
                          onClick={() => handleRequestSort('close')}
                          sx={{ color: 'white' }}
                        >
                          Close
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'highMinusOpen'}
                          direction={orderBy === 'highMinusOpen' ? order : 'asc'}
                          onClick={() => handleRequestSort('highMinusOpen')}
                          sx={{ color: 'white' }}
                        >
                          H-O
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'lowMinusOpen'}
                          direction={orderBy === 'lowMinusOpen' ? order : 'asc'}
                          onClick={() => handleRequestSort('lowMinusOpen')}
                          sx={{ color: 'white' }}
                        >
                          L-O
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'closeMinusOpen'}
                          direction={orderBy === 'closeMinusOpen' ? order : 'asc'}
                          onClick={() => handleRequestSort('closeMinusOpen')}
                          sx={{ color: 'white' }}
                        >
                          C-O
                        </TableSortLabel>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'volume'}
                          direction={orderBy === 'volume' ? order : 'asc'}
                          onClick={() => handleRequestSort('volume')}
                          sx={{ color: 'white' }}
                        >
                          Volume
                        </TableSortLabel>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'volume'}
                          direction={orderBy === 'volume' ? order : 'asc'}
                          onClick={() => handleRequestSort('volume')}
                          sx={{ color: 'white' }}
                        >
                          COpen
                        </TableSortLabel>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'volume'}
                          direction={orderBy === 'volume' ? order : 'asc'}
                          onClick={() => handleRequestSort('volume')}
                          sx={{ color: 'white' }}
                        >
                          CHigh
                        </TableSortLabel>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'volume'}
                          direction={orderBy === 'volume' ? order : 'asc'}
                          onClick={() => handleRequestSort('volume')}
                          sx={{ color: 'white' }}
                        >
                          CLow
                        </TableSortLabel>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <TableSortLabel
                          active={orderBy === 'volume'}
                          direction={orderBy === 'volume' ? order : 'asc'}
                          onClick={() => handleRequestSort('volume')}
                          sx={{ color: 'white' }}
                        >
                          CClose
                        </TableSortLabel>
                      </StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((entry) => (
                      <TableRow key={`${entry.symbol}-${entry.timestamp}`}>
                        <TableCell>
                          {new Date(entry.timestamp).toLocaleString()}
                        </TableCell>
                        <TableCell align="right">{entry.open}</TableCell>
                        <TableCell align="right">{entry.high}</TableCell>
                        <TableCell align="right">{entry.low}</TableCell>
                        <TableCell align="right">{entry.close}</TableCell>
                        <TableCell align="right">{entry.highMinusOpen}</TableCell>
                        <TableCell align="right">{entry.lowMinusOpen}</TableCell>
                        <TableCell align="right">{entry.closeMinusOpen}</TableCell>
                        <TableCell align="right">{entry.volume}</TableCell>
                        <TableCell align="right">{entry.current15MinopenMinusOpen}</TableCell>
                        <TableCell align="right">{entry.current15MinopenMinusHigh}</TableCell>
                        <TableCell align="right">{entry.current15MinopenMinusLow}</TableCell>
                        <TableCell align="right">{entry.current15MinopenMinusClose}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WatchlistOneMinuteData;
