import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  Alert, // Import Alert
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import axios from "axios";

const F_and_OT_Tab = () => {
  // State variables
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('INDEX'); // 'INDEX' or 'EQ'
  const [strikeCount, setStrikeCount] = useState(10);
  const [expiryDates, setExpiryDates] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState('');
  const [symbol, setSymbol] = useState('');
  const [optionsData, setOptionsData] = useState([]);
 


  // Snackbar state variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' | 'error' | 'warning' | 'info'

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Utility function to extract error messages
  const getErrorMessage = (error) => {
    if (error.response) {
      if (typeof error.response.data === 'string') {
        return error.response.data;
      } else if (error.response.data && error.response.data.message) {
        return error.response.data.message;
      }
    }
    return 'An unexpected error occurred.';
  };

  // Function to fetch suggestions from the API
  const fetchSuggestions = useCallback(async (query) => {
    if (query.length === 0) {
      setSymbolOptions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://api.arasu.online/api/Symbol/UnderSymSuggestions?query=${encodeURIComponent(query)}&limit=25`
      );
      if (!response.ok) {
        throw new Error(`Error fetching suggestions: ${response.statusText}`);
      }
      const data = await response.json();
      setSymbolOptions(data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      // setError('Failed to fetch symbol suggestions.');
      setSnackbarMessage('Failed to fetch symbol suggestions.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, []);

  // useEffect to call fetchSuggestions when inputValue changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchSuggestions(inputValue);
    }, 300); // Debounce the API call by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, fetchSuggestions]);

  // Fetch all F&O data on component mount
  useEffect(() => {
    const fetchAllFandOData = async () => {
      try {
        const response = await axios.get('https://api.arasu.online/api/Subscribe/F_and_O_Data');
        setOptionsData(response.data);
        setSnackbarMessage('F&O data fetched successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error fetching F&O data:', error);
        // setError('Failed to fetch F&O data.');
        setSnackbarMessage('Failed to fetch F&O data.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchAllFandOData();
  }, []);

  // Handle changes in the Autocomplete component
  const handleAutocompleteChange = async (event, newValue) => {
    setSelectedValue(newValue);

    const selectRadioBtn = selectedOption || '';
    const selectedSymbol = newValue?.underSym || '';
    let symbols = '';

    if (selectedSymbol) {
      if (selectRadioBtn === 'INDEX') {
        // eslint-disable-next-line no-useless-concat
        symbols = 'NSE:' + selectedSymbol + '50' + '-INDEX';
      } else if (selectRadioBtn === 'EQ') {
        symbols = 'NSE:' + selectedSymbol + '-EQ';
      } else {
        symbols = 'NSE:' + selectedSymbol;
      }
     
    } else {
      console.error('No symbol selected.');
      // setError('No symbol selected.');
      setSnackbarMessage('No symbol selected.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setSymbol(symbols);

    try {
      const response = await axios.get('https://api.arasu.online/api/Data/GetOptionsChain', {
        params: {
          symbol: symbols,
          strikeCount: strikeCount,
        },
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('token')}`,
        // },
      });

    

      if (response.data.status === 1) {
        const expiryData = response.data.data.data.expiryData.map((item) => ({
          date: item.Date,
          expiry: item.Expiry,
        }));
        setExpiryDates(expiryData);
        setSnackbarMessage('Expiry dates fetched successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        const message = response.data.message || 'Failed to fetch expiry dates.';
        setSnackbarMessage(message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (err) {
      console.error('Error fetching options chain:', err);
      // setError('An error occurred while fetching options chain data.');
      const message =
        getErrorMessage(err) ||
        'An error occurred while fetching options chain data.';
      setSnackbarMessage(message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Handle changes in the expiry date selection
  const handleExpiryChange = (event) => {
    setSelectedExpiry(event.target.value);
    // Fetch options data when expiry is selected
    handleFetchOptionsChain(event.target.value);
  };

  // Handle deletion of a single row
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://api.arasu.online/api/Subscribe/F_and_O_Data/${id}`);
      if (response.status === 200) {
        setOptionsData((prevRows) => prevRows.filter((row) => row.id !== id));
        setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== id));
        if (selectedValue && selectedValue.id === id) {
          setSelectedValue(null);
        }
        // Trigger success Snackbar
        setSnackbarMessage('Symbol deleted successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        const message = response.data?.message || 'Failed to delete the symbol.';
        setSnackbarMessage(message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error deleting symbol:', error);
      // Extract error message from the server response, if available
      const message =
        getErrorMessage(error) ||
        'An error occurred while deleting the symbol.';
      setSnackbarMessage(message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Handle select all checkbox click
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = optionsData.map((n) => n.id);
      setSelectedRows(newSelecteds);
      return;
    }
    setSelectedRows([]);
  };

  // Handle individual checkbox click
  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  // Handle deletion of selected rows
  const handleDeleteSelected = async () => {
    try {
      for (const id of selectedRows) {
        await axios.delete(`https://api.arasu.online/api/Subscribe/F_and_O_Data/${id}`);
      }
      setOptionsData((prevRows) => prevRows.filter((row) => !selectedRows.includes(row.id)));
      setSelectedRows([]);
      if (selectedValue && selectedRows.includes(selectedValue.id)) {
        setSelectedValue(null);
      }
      // Trigger success Snackbar
      setSnackbarMessage('Selected symbols deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting selected symbols:', error);
      // Extract error message from the server response, if available
      const message =
        getErrorMessage(error) ||
        'An error occurred while deleting selected symbols.';
      setSnackbarMessage(message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Check if a row is selected
  const isSelected = (id) => selectedRows.includes(id);

  const handleFetchOptionsChain = async (expiryValue) => {
    const expiry = expiryValue || selectedExpiry;
    if (!expiry) {
      // setError('Please select an expiry date.');
      setSnackbarMessage('Please select an expiry date.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }


    // setError('');

    try {
      const response = await axios.get('https://api.arasu.online/api/Data/GetOptionsChain', {
        params: {
          symbol: symbol,
          strikeCount: strikeCount,
          expiry: expiry,
        },
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('token')}`,
        // },
      });

      if (response.data.status === 1) {
       
        const optionsChainData = response.data.data.data.optionsChainData;
        const optionsWithId = optionsChainData.map((item, index) => ({
          ...item,
          id: item.id || `${item.Symbol}-${index}`,
        }));

        for (const option of optionsWithId) {
          try {
            await axios.post('https://api.arasu.online/api/Subscribe/F_and_O_Data', {
              symbol: option.Symbol,
            });
          } catch (postError) {
            console.error('Error saving F&O data:', postError.response?.data || postError.message);
            
            // Using setSnackbarMessage correctly
            const message = getErrorMessage(postError);
            setSnackbarMessage(message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            // Optionally, handle individual post errors here
            // For example, you can continue or set specific error messages
          }
        }

        const updatedResponse = await axios.get('https://api.arasu.online/api/Subscribe/F_and_O_Data');
        setOptionsData(updatedResponse.data);

        // Trigger success Snackbar
        setSnackbarMessage('Options chain data fetched and saved successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        const message = response.data.message || 'Failed to fetch options chain data.';
        setSnackbarMessage(message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (err) {
      console.error(err);
      // Extract error message from the server response, if available
      const message =
        getErrorMessage(err) ||
        'An error occurred while fetching options chain data.';
      setSnackbarMessage(message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
   
    }
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        {/* Snackbar for Toast Messages */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Duration in milliseconds
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position of the Snackbar
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Grid container spacing={2} alignItems="center">
          {/* Autocomplete Component */}
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              fullWidth
              options={symbolOptions}
              getOptionLabel={(option) => option.underSym || ''}
              value={selectedValue}
              onChange={handleAutocompleteChange}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Symbol" variant="outlined" />
              )}
            />
          </Grid>

          {/* Radio Buttons */}
          <Grid item >
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={selectedOption}
                onChange={(event) => setSelectedOption(event.target.value)}
              >
                <FormControlLabel value="INDEX" control={<Radio />} label="INDEX" />
                <FormControlLabel value="EQ" control={<Radio />} label="EQ" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Strike Count Input */}
          <Grid item >
            <TextField
              label="Strike Count"
              type="number"
              value={strikeCount}
              onChange={(e) => setStrikeCount(Number(e.target.value))}
              fullWidth
            />
          </Grid>

          {/* Expiry Dates */}
          {expiryDates.length > 0 && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel id="expiry-select-label">Expiry Date</InputLabel>
                <Select
                  labelId="expiry-select-label"
                  value={selectedExpiry}
                  label="Expiry Date"
                  onChange={handleExpiryChange}
                >
                  {expiryDates.map((expiry) => (
                    <MenuItem key={expiry.expiry} value={expiry.expiry}>
                      {expiry.date}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        {/* Delete Selected Button */}
        {optionsData.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteSelected}
              disabled={selectedRows.length === 0}
            >
              Delete Selected
            </Button>
          </Box>
        )}

        {/* Data Table */}
        {optionsData.length > 0 ? (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 && selectedRows.length < optionsData.length
                      }
                      checked={optionsData.length > 0 && selectedRows.length === optionsData.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all options',
                      }}
                    />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {optionsData.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      key={row.id}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleCheckboxClick(event, row.id)}
                          inputProps={{
                            'aria-labelledby': `enhanced-table-checkbox-${index}`,
                          }}
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.symbol.replace('NSE:', '')}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDelete(row.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography sx={{ mt: 2 }}>No data available.</Typography>
        )}
      </Box>
    </>
  );
};

export default F_and_OT_Tab;
