// Nifty50Contribution.jsx

import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Grid,
  TableSortLabel,
} from "@mui/material";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import HomePage from "./HomePage";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

// Styled Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  fontWeight: "400",
  fontSize: 14,
}));

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? theme.palette.action.hover : "inherit",
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const ContributionCell = styled(TableCell)(({ theme, contribution }) => ({
  color:
    contribution > 0 ? theme.palette.success.main : theme.palette.error.main,
  fontWeight: "400",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 100,
  maxHeight: 60,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[6],
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.0rem",
  fontWeight: 400,
  color: theme.palette.text.primary,
}));

const ValueTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "1.0rem",
  color: theme.palette.text.primary,
}));

const ChangeBox = styled(Box)(({ theme, change }) => ({
  display: "flex",
  alignItems: "center",
  color: change >= 0 ? theme.palette.success.main : theme.palette.error.main,
  marginTop: theme.spacing(1),
}));

// SummaryCard Component
const SummaryCard = ({ title, value, change }) => {
  return (
    <StyledCard>
      <CardContent>
        {/* Title and Change Indicator */}
        <Box display="flex" justifyContent="space-evenly" alignItems="center">
          <TitleTypography  fontSize="small">{title}</TitleTypography>
          <ValueTypography fontSize="small">
            {value !== null && value !== undefined ? value : "—"}
          </ValueTypography>
          {change !== undefined && change !== null && (
            <ChangeBox change={change}>
              {change >= 0 ? (
                <TrendingUpIcon fontSize="small" />
              ) : (
                <TrendingDownIcon fontSize="small" />
              )}
              <Typography variant="body2" sx={{ marginLeft: 0.5 }}>
                {Math.abs(change).toFixed(2)}
              </Typography>
            </ChangeBox>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
};



// Define the table headers
const headCells = [
  { id: 'no', label: 'No', numeric: false },
  { id: 'symbol', label: 'Symbol', numeric: false },
  { id: 'ltp', label: 'LTP', numeric: true },
  { id: 'change', label: 'Change', numeric: true },
  { id: 'preClose', label: 'Close', numeric: true },
  { id: 'contribution', label: 'Contribution', numeric: true },
];

// Comparator functions
const descendingComparator = (a, b, property) => {
  if (property === 'no') {
    return a.index - b.index;
  }

  const valueA = a[property];
  const valueB = b[property];

  if (valueB === null || valueB === undefined) return -1;
  if (valueA === null || valueA === undefined) return 1;

  if (typeof valueB === 'number' && typeof valueA === 'number') {
    return valueB - valueA;
  }

  return valueB.toString().localeCompare(valueA.toString());
};

const getComparator = (order, property) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, property)
    : (a, b) => -descendingComparator(a, b, property);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => ({ ...el, index }));
  stabilizedThis.sort((a, b) => {
    const orderResult = comparator(a, b);
    if (orderResult !== 0) return orderResult;
    return a.index - b.index;
  });
  return stabilizedThis;
};

// Main Component
const Nifty50Contribution = () => {
  // State Variables
  const [stocks, setStocks] = useState([]);
  const [connection, setConnection] = useState(null);

  // Nifty50 Metrics
  const [Nifty50Close, setNifty50Close] = useState(null);
  const [nifty50Ltp, setNifty50Ltp] = useState(null);
  const [nifty50Change, setNifty50Change] = useState(null);
  const [contributionTotal, setContributionTotal] = useState(null);
  const [contribution1, setContribution1] = useState(null);

  // Error States
  const [dataError, setDataError] = useState(null);
 
  // Loading State
  const [loading, setLoading] = useState(true);

  // Sorting State
  const [order, setOrder] = useState('asc'); // 'asc' or 'desc'
  const [orderBy, setOrderBy] = useState('no'); // Default sort by 'No'

 
  // Fetch initial stock data
  useEffect(() => {
    fetchStocks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStocks = async () => {
    try {
      const response = await fetch(
        "https://api.arasu.online/api/stock/get-all-Stock"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // Initialize additional fields for each stock
      const initializedData = data.map((stock, index) => ({
        ...stock,
        ltp: stock.ltp || null,
        preClose: stock.preClose || null,
        change: stock.change || null,
        ltpFreeFloat: stock.ltpFreeFloat || null,
        closeFreeFloat: stock.closeFreeFloat || null,
        index, // Preserve original index for stable sorting
      }));
      setStocks(initializedData);
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching stocks:", error);
      setDataError("Failed to fetch stock data. Please try again later.");
      setLoading(false);
    }
  };

  // Format symbol to match the expected format
  const formatSymbol = (symbol) => {
    return symbol.startsWith("NSE:")
      ? symbol.trim().toUpperCase()
      : `NSE:${symbol.trim().toUpperCase()}`;
  };

  // Initialize SignalR connection
  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming token is stored here
    const retryDelays = [0, 2000, 10000, 30000]; // Attempts at 0ms, 2s, 10s, and 30s

    const newConnection = new HubConnectionBuilder()
      .withUrl("https://api.arasu.online/ltpHub", {
        accessTokenFactory: () => token, // Include token if SignalR hub requires authentication
      })
      .withAutomaticReconnect(retryDelays)
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(newConnection);
  }, []);

  // Function to handle received LTP data
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleReceiveLtp = (message) => {
  

    // Extract data from the message
    const receivedSymbol = formatSymbol(message.symbol);
    const receivedLtp = message.ltp;
    const previous_Close = message.previousClose;
    const Change = message.change;

    const Nifty50Symbol = "NSE:NIFTY50-INDEX";

    // Check if the received symbol is NIFTY50-INDEX
    if (Nifty50Symbol === receivedSymbol) {
      setNifty50Close(previous_Close);
      setNifty50Ltp(receivedLtp);
      setNifty50Change(Change);
      return;
    }

    // Update the stocks state without calculating contribution yet
    setStocks((prevStocks) =>
      prevStocks.map((stock) => {
        const formattedSymbol = `NSE:${stock.symbol}-EQ`;
        const freeFloatValue = stock.freefloatIssuedSize;
        const LtpFreeFloat = receivedLtp * freeFloatValue;
        const CloseFreeFloat = previous_Close * freeFloatValue;

        if (formattedSymbol === receivedSymbol) {
          return {
            ...stock,
            ltp: receivedLtp,
            preClose: previous_Close,
            change: Change,
            ltpFreeFloat: LtpFreeFloat,
            closeFreeFloat: CloseFreeFloat,
          };
        }
        return stock;
      })
    );
  };

  // Handle SignalR connection and events
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
        
        

          // Subscribe to ReceiveLtp event
          connection.on("ReceiveLtp", handleReceiveLtp);

          // Handle reconnection
          connection.onreconnected((connectionId) => {
            console.log("SignalR reconnected. Connection ID:", connectionId);
            // Re-subscribe to events
            connection.on("ReceiveLtp", handleReceiveLtp);
            
          });

          connection.onreconnecting((error) => {
            console.warn("SignalR reconnecting:", error);
           
          });

          connection.onclose((error) => {
            console.error("SignalR connection closed:", error);
           
          });
        })
        .catch((err) => {
          console.error("Failed to connect to SignalR hub:", err);
         
        });

      // Cleanup on unmount
      return () => {
        connection
          .stop()
          .then(() => console.log("Disconnected from SignalR hub"))
          .catch((err) =>
            console.error("Error disconnecting from SignalR hub:", err)
          );
      };
    }
  }, [connection, handleReceiveLtp]);

  // Calculate totals and contributions whenever stocks or Nifty50Close change
  useEffect(() => {
    if (stocks.length === 0 || Nifty50Close === null) {
      // Do not calculate if stocks are not loaded or Nifty50Close is not set
      setContributionTotal(0);
      setContribution1(0);
      return;
    }

    // Calculate totalLtpFreeFloat and totalCloseFreeFloat
    const totalLtp = stocks.reduce(
      (sum, stock) => sum + (stock.ltp || 0) * stock.freefloatIssuedSize,
      0
    );
    const totalClose = stocks.reduce(
      (sum, stock) => sum + (stock.preClose || 0) * stock.freefloatIssuedSize,
      0
    );

    // Calculate C.Nifty50
    const cNifty50 =
      totalClose !== 0 ? (totalLtp / totalClose) * Nifty50Close : 0;
    setContribution1(cNifty50);

    // Calculate totalContribution as sum of individual contributions
    const totalContribution = stocks.reduce((sum, stock) => {
      const ltpFreeFloat = (stock.ltp || 0) * stock.freefloatIssuedSize;
      const closeFreeFloat = (stock.preClose || 0) * stock.freefloatIssuedSize;
      const contribution =
        totalClose !== 0
          ? ((ltpFreeFloat - closeFreeFloat) / totalClose) * Nifty50Close
          : 0;
      return sum + contribution;
    }, 0);
    setContributionTotal(totalContribution);
  }, [stocks, Nifty50Close]);

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Define a custom theme
  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#1976d2", // Customize as needed
      },
      secondary: {
        main: "#dc004e", // Customize as needed
      },
    },
    typography: {
      h6: {
        fontWeight: 400,
      },
      subtitle1: {
        fontWeight: 400,
      },
    },
  });

  // Calculate contributions using useMemo to optimize performance
  const stocksWithContributions = useMemo(() => {
    if (stocks.length === 0 || Nifty50Close === null) return [];

    const totalClose = stocks.reduce(
      (sum, stock) => sum + (stock.preClose || 0) * stock.freefloatIssuedSize,
      0
    );

    return stocks.map((stock) => {
      const ltpFreeFloat = (stock.ltp || 0) * stock.freefloatIssuedSize;
      const closeFreeFloat = (stock.preClose || 0) * stock.freefloatIssuedSize;
      const contribution =
        totalClose !== 0
          ? ((ltpFreeFloat - closeFreeFloat) / totalClose) * Nifty50Close
          : 0;
      return { ...stock, contribution };
    });
  }, [stocks, Nifty50Close]);

  return (
    <ThemeProvider theme={customTheme}>
      <HomePage />
      <Paper sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
        {/* Summary Metrics */}
        <Box sx={{ m: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={3}>
              <SummaryCard
                title="Nifty50 :"
                value={nifty50Ltp !== null ? nifty50Ltp.toFixed(2) : "—"}
                change={nifty50Change}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <SummaryCard
                title="Nifty Prev Close"
                value={Nifty50Close !== null ? Nifty50Close.toFixed(2) : "—"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <SummaryCard
                title="C.Nifty50:"
                value={
                  contribution1 !== null ? contribution1.toFixed(2) : "—"
                }
                change={contributionTotal}
              />
            </Grid>

          </Grid>
        </Box>

        {/* Loading, Data Error, or Table */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress size={60} color="primary" />
          </Box>
        ) : dataError ? (
          <Alert severity="error">{dataError}</Alert>
        ) : (
          <>
            {/* Connection Error Message */}
         

            {/* Stock Data Table */}
            <TableContainer sx={{ maxHeight: 650 }}>
              <Table stickyHeader aria-label="stock table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={{ visuallyHidden: true }}>
                              {order === 'desc' ? '' : ''}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(stocksWithContributions, getComparator(order, orderBy)).map((stock, index) => (
                    <StyledTableRow
                      key={`${stock.symbol}-${index}`}
                      index={index}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell>{stock.symbol}</TableCell>
                      <TableCell align="right">
                        {stock.ltp !== undefined && stock.ltp !== null
                          ? stock.ltp.toFixed(2)
                          : "—"}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color:
                            stock.change > 0
                              ? "success.main"
                              : stock.change < 0
                              ? "error.main"
                              : "text.primary",
                          fontWeight: "400",
                        }}
                      >
                        {stock.change !== undefined && stock.change !== null
                          ? `${stock.change.toFixed(2)}`
                          : "—"}
                      </TableCell>
                      <TableCell align="right">
                        {stock.preClose !== undefined && stock.preClose !== null
                          ? stock.preClose.toFixed(2)
                          : "—"}
                      </TableCell>
                      <ContributionCell
                        align="right"
                        contribution={stock.contribution}
                      >
                        {stock.contribution !== null && stock.contribution !== undefined
                          ? stock.contribution.toFixed(2)
                          : "—"}
                      </ContributionCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="right"
                      sx={{ fontWeight: "bold", fontSize: 16 }}
                    >
                      Totals:{" "}
                      {contributionTotal !== null
                        ? contributionTotal.toFixed(2)
                        : "—"}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default Nifty50Contribution;
