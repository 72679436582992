import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Button,
  Box,
  Snackbar,
  Alert,
  Tooltip, // Import Tooltip
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { DrawOutlined } from "@mui/icons-material";
import UserDataEditModel from "./UserDataEditModel";
import HomePage from "./HomePage";
import axios from "axios";

const RegisterApiDetails = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [formData, setFormData] = useState({
    Id: "",
    UserId: "",
    ClientId: "",
    Secretkey: "",
    Pin: "",
    RedirectUri: "",
    RefreshTokenUrl: "",
    AppHashId: "",
    RefreshToken: "",
    AccessToken: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.arasu.online/api/Auth/show_apidata`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
    

      let fetchedData = response.data;
      if (response.data.items) {
        fetchedData = response.data.items;
      } else if (!Array.isArray(response.data)) {
        fetchedData = [response.data];
      }

      setData(fetchedData);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err);
      setLoading(false);
    }
  };

  const handleAction = (rowData) => {
    setFormData({
      Id: rowData.id || rowData.UserId || "",
      UserId: rowData.userId || rowData.UserId || "",
      ClientId: rowData.clientId || rowData.ClientId || "",
      Secretkey: rowData.secretKey || rowData.Secretkey || "",
      Pin: rowData.pin || rowData.Pin || "",
      RedirectUri: rowData.redirectUri || rowData.RedirectUri || "",
      RefreshTokenUrl: rowData.refreshTokenUrl || rowData.RefreshTokenUrl || "",
      AppHashId: rowData.appHashId || rowData.AppHashId || "",
      RefreshToken: rowData.refreshToken || rowData.RefreshToken || "",
      AccessToken: rowData.accessToken || rowData.AccessToken || "",
    });
    setOpen(true);
  };

  const handleCloseModal = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        "https://api.arasu.online/api/Auth/update-user",
        formData
      );

      if (response.status === 200) {
        setMessage("User details updated successfully.");
        setSnackbarOpen(true);
        handleCloseModal();
        fetchData();
      } else {
        setMessage("Unexpected response from server.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      if (error.response) {
        setMessage(error.response.data.Message || "Error updating details.");
      } else {
        setMessage("Network error. Please try again.");
      }
      setSnackbarOpen(true);
    }
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <p>Loading data...</p>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <p>Error loading data: {error.message}</p>
      </Box>
    );
  }

  // Define the fields to display with their labels and corresponding keys
  const fields = [
    { label: "Client Id", key: "clientId" },
    { label: "Secret Key", key: "secretKey" },
    { label: "Pin", key: "pin" },
    { label: "Redirect Uri", key: "redirectUri" },
    { label: "Refresh Token Url", key: "refreshTokenUrl" },
    { label: "App Hash Id", key: "appHashId" },
    { label: "Refresh Token", key: "refreshToken" },
    { label: "Access Token", key: "accessToken" },
  ];

  return (
    <>
      <HomePage />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            mt: 3,
            boxShadow: 2,
            borderRadius: "3px",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            width: "90%", // Adjust width as needed
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "100%",
              overflowY: "auto",
              scrollBehavior: "smooth",
              flexGrow: 1,
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#ffffff",
                      backgroundColor: "#83d600",
                      textAlign: "center",
                      border: "1px solid #e0e0e0",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      padding: "12px",
                    }}
                  >
                    Field
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#ffffff",
                      backgroundColor: "#83d600",
                      textAlign: "center",
                      border: "1px solid #e0e0e0",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      padding: "12px",
                      maxWidth: "300px", // Set a max width for the Value column
                    }}
                  >
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((row, index) => (
                    <React.Fragment key={index}>
                      {fields.map((field) => (
                        <TableRow
                          key={field.key}
                          hover
                          sx={{
                            border: "1px solid #e0e0e0",
                          }}
                        >
                          <TableCell
                            sx={{
                              textAlign: "center",
                              padding: "8px",
                              border: "1px solid #e0e0e0",
                              fontWeight: "bold",
                              backgroundColor: "#f5f5f5",
                              width: "200px", // Set fixed width for Field column
                              maxWidth: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {field.label}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "left", // Changed to left for better readability
                              padding: "12px",
                              border: "1px solid #e0e0e0",
                              maxWidth: "500px", // Define max width
                              overflow: "auto", // Enable scrolling
                              whiteSpace: "nowrap", // Prevent text from wrapping
                              textOverflow: "ellipsis", // Show ellipsis for overflow
                            }}
                          >
                            <Box
                              sx={{
                                padding:"10px",
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Tooltip title={row[field.key] || "N/A"}>
                                <span>{row[field.key] || "N/A"}</span>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={2} sx={{ textAlign: "center", padding: "10px" }}>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#8bd616",
                              color: "#ffffff",
                              padding: "6px 16px",
                              "&:hover": {
                                backgroundColor: "#acea4b",
                                color: "#000000",
                              },
                            }}
                            onClick={() => handleAction(row)}
                            startIcon={<DrawOutlined />}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                      {/* Optional: Add a separator for multiple data objects */}
                      {data.length > 1 && index < data.length - 1 && (
                        <TableRow>
                          <TableCell colSpan={2} sx={{ padding: "8px" }}>
                            <hr />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        {/* User Data Edit Modal */}
        <UserDataEditModel
          open={open}
          handleClose={handleCloseModal}
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />

        {/* Snackbar Notification */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setSnackbarOpen(false)} 
            severity={message.includes("successfully") ? "success" : "error"} 
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default RegisterApiDetails;
