// src/pages/FifteenMinData.js

import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Grid,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import HomePage from "./HomePage";
import useMediaQuery from "@mui/material/useMediaQuery";
import CryptoJS from "crypto-js";
import { AuthContext } from "../AuthContext";

// Styled components for better readability and maintainability
const StyledTableCell = styled(TableCell)(
  ({ theme, bgcolor, bordercolor }) => ({
    fontWeight: "bold",
    color: theme.palette.common.white,
    backgroundColor: bgcolor || theme.palette.primary.main,
    textAlign: "center",
    borderLeft: `1px solid ${bordercolor || theme.palette.divider}`,
    cursor: "pointer",
    userSelect: "none",
    position: "sticky",
    top: 0,
    zIndex: 1,
  })
);

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.common.white,
  },
  textTransform: "none",
}));

// Custom hook to detect five-tap on touch devices
const useFiveTap = (onFiveTap, delay = 1000) => {
  const tapCount = useRef(0);
  const timer = useRef(null);

  const handleTouchStart = useCallback(
    (event) => {
      tapCount.current += 1;

      if (tapCount.current === 1) {
        // Start the timer on the first tap
        timer.current = setTimeout(() => {
          tapCount.current = 0;
        }, delay);
      }

      if (tapCount.current === 5) {
        onFiveTap(event);
        clearTimeout(timer.current);
        tapCount.current = 0;
      }
    },
    [onFiveTap, delay]
  );

  return handleTouchStart;
};

// DataTable component for reusability
const DataTable = ({
  headers,
  hiddenHeaders,
  data,
  order,
  orderBy,
  handleSort,
  theme,
  handleAction,
}) => (
  <Table stickyHeader aria-label="15-min data table" sx={{ marginBottom: "20px" }}>
    <TableHead>
      <TableRow>
        {headers
          .filter((header) => !hiddenHeaders.includes(header.value))
          .map((header) => (
            <StyledTableCell
              key={header.value}
              bgcolor={header.color}
              bordercolor={header.color}
              onClick={() => header.value && handleSort(header.value)}
              sx={{
                cursor: header.value ? "pointer" : "default",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {header.label}
                </Typography>
                {orderBy === header.value ? (
                  order === "asc" ? (
                    <ArrowUpward fontSize="small" />
                  ) : (
                    <ArrowDownward fontSize="small" />
                  )
                ) : null}
              </Box>
            </StyledTableCell>
          ))}
        {/* Extra header for Actions */}
        <StyledTableCell
          sx={{
            fontWeight: "bold",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            textAlign: "center",
            cursor: "default",
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}
        >
          Actions
        </StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((row, index) => (
        <TableRow
          key={index}
          hover
          sx={{
            backgroundColor:
              index % 2 === 0
                ? theme.palette.action.hover
                : theme.palette.background.default,
          }}
        >
          {headers
            .filter((header) => !hiddenHeaders.includes(header.value))
            .map((header) => (
              <TableCell
                key={header.value}
                sx={{
                  textAlign: "center",
                  padding: { xs: "6px", sm: "8px" },
                  borderLeft: `1px solid ${
                    header.color || theme.palette.divider
                  }`,
                }}
              >
                {row[header.value]}
              </TableCell>
            ))}

          {/* Action Button */}
          <TableCell
            sx={{
              textAlign: "center",
              padding: { xs: "6px", sm: "8px" },
              borderLeft: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Tooltip title="View 1-Min Data">
              <ActionButton
                variant="contained"
                onClick={() => handleAction(row)}
                size="small"
                aria-label={`View 1-Min Data for ${row.symbol || "symbol"}`}
              >
                View1M
              </ActionButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const FifteenMinData = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // md and up for desktops and laptops
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [hiddenHeaders, setHiddenHeaders] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const symbol = query.get("symbol");
  const date = query.get("date");
  const user = query.get("uuu");

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]);

  const encryptionKey = "your-secure-key";

  const bytesSymbol = CryptoJS.AES.decrypt(symbol || "", encryptionKey);
  const decryptedSymbol = bytesSymbol.toString(CryptoJS.enc.Utf8).trim();

  // Decrypt the date
  const bytesDate = CryptoJS.AES.decrypt(date || "", encryptionKey);
  const decryptedDate = bytesDate.toString(CryptoJS.enc.Utf8).trim();

  // State for "Full 1 Min Data" checkbox
  const [fullOneMinData, setFullOneMinData] = useState(false);

  // Unified notification state
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success", // 'success' | 'error' | 'warning' | 'info'
  });

  // State for full-screen mode
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { logout } = useContext(AuthContext);

  // Function to remove query parameter
  const removeQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(param)) {
      searchParams.delete(param);
      const newSearch = searchParams.toString();
      navigate(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`, { replace: true });
    }
  };

  // Function to assign specific colors based on key
  const getHeaderColor = (key) => {
    switch (key) {
      case "O_C_D_TH":
      case "H_C_D_TH":
      case "L_C_D_TH":
      case "C_C_D_TH":
        return theme.palette.success.main; // Green
      case "O_C_D_PD":
      case "H_C_D_PD":
      case "L_C_D_PD":
      case "C_C_D_PD":
        return theme.palette.warning.main; // Orange
      case "O_C_D_CD":
      case "H_C_D_CD":
      case "L_C_D_CD":
      case "C_C_D_CD":
        return "#9847f5"; // Custom color
      default:
        return theme.palette.primary.main; // Default primary color
    }
  };

  // Function to fetch data from backend
  const fetchData = async () => {
    setIsLoading(true);
    // Reset any existing notifications
    setNotification({
      open: false,
      message: "",
      severity: "success",
    });
    try {
      const token = localStorage.getItem("token");
      const storedUser = localStorage.getItem("user");

      if (!storedUser || !JSON.parse(storedUser)) {
        logout();
        navigate("/unauthorized");
        return;
      }

      if (!decryptedSymbol || !decryptedDate) {
        console.error("Encrypted data is missing.");
        setNotification({
          open: true,
          message: "Invalid symbol or date data.",
          severity: "error",
        });
        setIsLoading(false);
        return;
      }

      const payload = {
        symbol: decryptedSymbol,
        date: decryptedDate,
        fullData: fullOneMinData,
      };

      const response = await axios.post(
        "https://api.arasu.online/api/Data/GetFifteenMinData",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (Array.isArray(response.data)) {
        setData(response.data);

        if (response.data.length > 0) {
          const dynamicHeaders = Object.keys(response.data[0]).map((key) => ({
            label: key,
            value: key,
            color: getHeaderColor(key),
          }));
          setHeaders(dynamicHeaders);

          // By default, no headers are hidden when new headers are set
          setHiddenHeaders([]);
          removeQueryParam("uuu");
        } else {
          setHeaders([]);
          setHiddenHeaders([]);
          // Optionally notify no data
          setNotification({
            open: true,
            message: "No data available for the selected date.",
            severity: "info",
          });
        }
        // If data is loaded successfully, notify success
      } else {
        setData([]);
        setHeaders([]);
        setHiddenHeaders([]);
        setNotification({
          open: true,
          message: "Invalid data format received from server.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNotification({
        open: true,
        message: "Failed to retrieve data. Please try again.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (symbol && date) {
      fetchData();
    } else {
      setNotification({
        open: true,
        message: "Symbol or date parameter is missing.",
        severity: "error",
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol, date, fullOneMinData]);

  // Function to handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);

    const sortedData = [...data].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      // Handle undefined or null values
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      // Numeric sorting
      if (typeof aValue === "number" && typeof bValue === "number") {
        return isAsc ? aValue - bValue : bValue - aValue;
      }

      // Date sorting
      if (isValidDate(aValue) && isValidDate(bValue)) {
        return isAsc
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      // String sorting
      return isAsc
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setData(sortedData);
  };

  // Helper function to check if a string is a valid date
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date);
  };

  // Function to handle action button click
  const handleAction = (row) => {
    const dateTimeStr = row.DateTime || row.dateTime || row.Date || "";

    if (!dateTimeStr) {
      console.error("DateTime string is undefined in row:", row);
      setNotification({
        open: true,
        message: "DateTime information is missing for this entry.",
        severity: "error",
      });
      return;
    }

    const encryptionKey = 'your-secure-key';

    const encryptedDateTime = CryptoJS.AES.encrypt(dateTimeStr, encryptionKey).toString();
    const userId = localStorage.getItem("userId");

    const url = `${
      window.location.origin
    }/one-min-data?symbol=${encodeURIComponent(
      symbol
    )}&dateTime=${encodeURIComponent(encryptedDateTime)}&fullData=${fullOneMinData}&uuu=${userId}`;

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  // Handle header selection change
  const handleHeaderChange = (event) => {
    const value = event.target.value;

    if (value.includes("select_all")) {
      if (hiddenHeaders.length === headers.length) {
        setHiddenHeaders([]); // Unhide all if all are hidden
      } else {
        setHiddenHeaders(headers.map((header) => header.value)); // Hide all if not all are hidden
      }
    } else {
      setHiddenHeaders(value);
    }
  };

  // Function to handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  // Function to handle Full Screen open
  const handleFullScreenOpen = () => {
    setIsFullScreen(true);
  };

 

  // Function to handle five-tap to toggle full-screen
  const handleFiveTap = () => {
    setIsFullScreen((prev) => !prev);
  };

  // Integrate the custom five-tap hook
  const fiveTapHandler = useFiveTap(handleFiveTap, 1000); // 1000ms delay between taps

  // Handle Escape key to exit full-screen mode
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isFullScreen) {
        setIsFullScreen(false);
      }
    };

    if (isFullScreen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullScreen]);

  return (
    <Box
      sx={{
        p: { xs: 1, sm: 2 },
        marginBottom: "50px",
        position: isFullScreen ? "fixed" : "relative",
        top: isFullScreen ? 0 : "auto",
        left: isFullScreen ? 0 : "auto",
        width: isFullScreen ? "100%" : "auto",
        height: isFullScreen ? "100%" : "auto",
        backgroundColor: isFullScreen ? theme.palette.background.paper : "inherit",
        zIndex: isFullScreen ? 2000 : "auto",
        overflow: isFullScreen ? "auto" : "visible",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <HomePage /> {/* Ensure this component is implemented correctly */}
      {/* Header Section */}
      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" sx={{ mt: { xs: 2, sm: 0 } }}>
            15-Minute Data for {decryptedSymbol} on {decryptedDate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* Full Screen Button - Visible only on Desktop and Laptop */}
          {isDesktop && data.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFullScreenOpen}
              sx={{ mr: 2 }}
              aria-label="Enter Full Screen Mode"
            >
              Full Screen
            </Button>
          )}

          {/* Full 1 Min Data Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={fullOneMinData}
                onChange={(e) => setFullOneMinData(e.target.checked)}
                color="primary"
                inputProps={{
                  "aria-label": "Full 1 Min Data Checkbox",
                }}
              />
            }
            label="Full 1 Min Data"
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          {/* Spacer or additional controls if needed */}
        </Grid>
        <Grid item xs={12} sm={2}>
          {/* Hide Columns Selector */}
          {data.length > 0 && (
            <FormControl fullWidth variant="outlined">
              <InputLabel id="header-select-label">Hide Columns</InputLabel>
              <Select
                labelId="header-select-label"
                id="header-select"
                multiple
                value={hiddenHeaders}
                onChange={handleHeaderChange}
                label="Hide Columns"
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>None</em>;
                  }

                  if (selected.length === headers.length) {
                    return "All Columns Hidden";
                  }

                  return `${selected.length} column(s) hidden`;
                }}
              >
                {/* Select All Option */}
                <MenuItem key="select_all" value="select_all">
                  <Checkbox
                    checked={
                      hiddenHeaders.length === headers.length &&
                      headers.length > 0
                    }
                    indeterminate={
                      hiddenHeaders.length > 0 &&
                      hiddenHeaders.length < headers.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>

                {/* Individual Options */}
                {headers.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={hiddenHeaders.includes(item.value)} />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
      {/* Loading Indicator */}
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {/* Data Table */}
      {!isLoading && data.length > 0 && (
        <TableContainer
          component={Paper}
          onDoubleClick={isDesktop ? fiveTapHandler : undefined} // Handle five-tap for desktop (optional)
          onTouchStart={!isDesktop ? fiveTapHandler : undefined} // Attach five-tap handler for mobile
          sx={{
            width: "100%",
            overflowY: "auto",
            overflowX: "auto",
            marginBottom: "100px",
            scrollBehavior: "smooth",
            boxShadow: 3,
            position: isFullScreen ? "fixed" : "relative", // Fixed position for full screen
            top: isFullScreen ? 0 : "auto",
            left: isFullScreen ? 0 : "auto",
            zIndex: isFullScreen ? 2000 : "auto",
            backgroundColor: isFullScreen
              ? theme.palette.background.paper
              : "inherit",
            height: isFullScreen ? "90%" : "90%",
            maxHeight: isFullScreen ? "90%" : "90%", // Adjust height based on state
            transition: "all 0.3s ease-in-out",
          }}
        >
        
       
          {/* Data Table */}
          <DataTable
            headers={headers}
            hiddenHeaders={hiddenHeaders}
            data={data}
            order={order}
            orderBy={orderBy}
            handleSort={handleSort}
            theme={theme}
            handleAction={handleAction}
          />
        </TableContainer>
      )}
      {/* No Data Message */}
      {!isLoading && !notification.open && data.length === 0 && (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 4 }}>
          No data available for the selected date.
        </Typography>
      )}
      {/* Snackbar for notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={notification.severity}
          sx={{
            width: "100%",
            fontSize: { xs: "0.8rem", sm: "1rem" },
            padding: { xs: 1, sm: 2 },
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FifteenMinData;
