import React, { useContext, useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Link,
  Avatar,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { login, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/main");
    }
  }, [isAuthenticated, navigate]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://api.arasu.online/api/Auth/Login", {
        username,
        password,
      });

     

      if (response.data.message === "Login successful") {
        login(response.data.user.id);
        localStorage.setItem("userId", response.data.user.id);
        localStorage.setItem("username", response.data.user.username);
        // navigate("/main");
      }
    } catch (error) {
      setSnackbarMessage(
        error.response?.data?.message || "Login failed. Please try again."
      );
      setSnackbarOpen(true);
      console.error("Error:", error);
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: theme.spacing(2),
      }}
    >
      <Card
        sx={{
          borderRadius: 4,
          boxShadow: 5,
          width: "100%",
          padding: theme.spacing(4),
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
          },
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            component="h1"
            gutterBottom
            align="center"
          >
            Sign In
          </Typography>
        </Box>
        <CardContent>
          <form onSubmit={handleLogin}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username"
              required
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              required
            />
            <Box
              display="flex"
              justifyContent="flex-end"
              mt={1}
              mb={2}
              sx={{ wordBreak: "break-word" }}
            >
              <Link href="#" underline="hover">
                Forgot password?
              </Link>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 2,
                height: 45,
                fontSize: isMobile ? "0.875rem" : "1rem",
              }}
            >
              Sign In
            </Button>
          </form>
          <Box textAlign="center" mt={3}>
            <Typography variant="body2">
              Don’t have an account?{" "}
              <Link href="/userRegister" underline="hover" color="primary">
                Sign Up
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginPage;
