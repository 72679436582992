import React, { useContext, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Container,
  Box,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Collapse,
  Snackbar,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoginOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../AuthContext";

// Styled components

const NavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#9adf26",
  color: "#000000", 
  borderRadius: "5px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    borderRadius: "0",
  },
}));

const HomePage = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  // State for Drawer submenu
  const [drawerOptionChainOpen, setdrawerOptionChainOpen] = useState(false);
  const [drawerSettingsOpen, setdrawerSettingsOpen] = useState(false);

  // Snackbar state variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' | 'error' | 'warning' | 'info'

 

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionMenuOpen = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleOptionMenuClose = () => {
    setAnchorE2(null);
   // Close submenu when main menu closes
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // Close submenu when main menu closes
  };

  

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
    handleMenuClose();
  };

  const handleFyresLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(
        "https://api.arasu.online/api/Auth/client-data"
      );

      const authUrl = response.data.authUrl;

    
      if (authUrl) {
        window.location.href = authUrl;
      } else {
        console.error("Auth URL not found in the response.");
        setSnackbarMessage("Failed to retrieve authentication URL.");
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching auth URL:", error);
      setSnackbarMessage("Failed to initiate login.");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Handlers for Drawer submenu
  const handleDrawerOptionChainClick = () => {
    setdrawerOptionChainOpen(!drawerOptionChainOpen);
  };

  const handleDrawerSettingsClick = () => {
    setdrawerSettingsOpen(!drawerSettingsOpen);
  };


  const handleLogout = () => {
    logout();
    navigate('/');
  };

 

  return (
    <>
      {/* Navigation Bar */}
      {!isMobileOrTablet && (
        <NavBar position="fixed">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              FYERS
            </Typography>
            <Button
              color="inherit"
              onClick={() => handleNavigation("/main")}
              sx={{ marginRight: "5px" }}
            >
              Home
            </Button>
            <Button
              color="inherit"
              onClick={() => handleNavigation("/watchlist")}
              sx={{ marginRight: "5px" }}
            >
              Watch List
            </Button>
            <Button
              color="inherit"
              onClick={() => handleNavigation("/LastData")}
              sx={{ marginRight: "5px" }}
            >
              View Data
            </Button>

            <Button
              color="inherit"
              onClick={handleOptionMenuOpen}
              endIcon={<ExpandMoreIcon />}
              sx={{ marginRight: "5px" }}
            >
              Option Chain
            </Button>
            <Menu
              anchorEl={anchorE2}
              open={Boolean(anchorE2)}
              onClose={handleOptionMenuClose}
            >
              <MenuItem onClick={() => handleNavigation("/options-chain")}>
                Option Chain
              </MenuItem>

              <MenuItem onClick={() => handleNavigation("/options-chain-live")}>
              Option Chain Live
              </MenuItem>

            </Menu>




            <Button
              color="inherit"
              onClick={() => handleNavigation("/watchlist-one-min-data")}
              sx={{ marginRight: "5px" }}
            >
             Watchlist One Min 
            </Button>

            <Button
              color="inherit"
              onClick={() => handleNavigation("/nifty50-cont")}
              sx={{ marginRight: "5px" }}
            >
             Nif 50 Contribution
            </Button>



            {/* Settings Menu */}
            <Button
              color="inherit"
              onClick={handleMenuOpen}
              endIcon={<ExpandMoreIcon />}
              sx={{ marginRight: "5px" }}
            >
              Settings
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
             
              <MenuItem onClick={() => handleNavigation("/settings")}>
                Settings Page
              </MenuItem>


              <MenuItem onClick={handleFyresLogin}>
                <Box display="flex" alignItems="center">
                 Fyers Login
                  <LoginOutlined sx={{ ml: 1 }} />
                </Box>
              </MenuItem>

              <MenuItem onClick={handleLogout}>
                <Box display="flex" alignItems="center">
                  Logout
                  <LoginOutlined sx={{ ml: 1 }} />
                </Box>
              </MenuItem>

            </Menu>
          </Toolbar>
        </NavBar>
      )}

      {/* Drawer for mobile and tablet menu */}
      {isMobileOrTablet && (
        <NavBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
            FYERS
            </Typography>
          </Toolbar>
        </NavBar>
      )}

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          // onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={() => handleNavigation("/main")}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation("/watchlist")}>
              <ListItemText primary="Watchlist" />
            </ListItem>

            <ListItem button onClick={() => handleNavigation("/LastData")}>
              <ListItemText primary="View Data" />
            </ListItem>

         
            {/* SymbolUpdate Submenu in Drawer */}
            <ListItem button onClick={handleDrawerOptionChainClick}>
              <ListItemText primary="Option Chain" />
              {drawerOptionChainOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={drawerOptionChainOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  sx={{ pl: 4 }}
                  onClick={() => handleNavigation("/options-chain")}
                >
                  <ListItemText primary="Option Chain" />
                </ListItem>
                <ListItem
                  button
                  sx={{ pl: 4 }}
                  onClick={() => handleNavigation("/options-chain-live")}
                >
                  <ListItemText primary="Option Chain Live" />
                </ListItem>
              </List>
            </Collapse>

            {/* <ListItem button onClick={() => handleNavigation("/Option-strategies")}>
              <ListItemText primary="OptionChanin Strategies" />
            </ListItem> */}

            <ListItem button onClick={() => handleNavigation("/nifty50-cont")}>
              <ListItemText primary="Nif 50 Contribution" />
            </ListItem>


            <ListItem button onClick={handleDrawerSettingsClick}>
              <ListItemText primary="Settings" />
              {drawerSettingsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={drawerSettingsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  sx={{ pl: 4 }}
                  onClick={() => handleNavigation("/settings")}
                >
                  <ListItemText primary="Settings Page" />
                </ListItem>

                <ListItem
                  button
                  sx={{ pl: 4 }}
                  onClick={handleFyresLogin}
                >
                  <ListItemText primary="Fyers Login" />
                  <LoginOutlined sx={{marginRight:"100px"}} />
                </ListItem>

                <ListItem
                  button
                  sx={{ pl: 4 }}
                  onClick={handleLogout}
                >
                  <ListItemText primary="Logout" />
                  <LoginOutlined sx={{marginRight:"100px"}} />
                </ListItem>

              </List>
            </Collapse>

            
          </List>
        </Box>
      </Drawer>

      <Container maxWidth="xl" sx={{ mt: 10 }}>
        {/* Add routing components here based on routes to display the selected page */}
      </Container>

      {/* Snackbar for Toast Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Duration in milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position of the Snackbar
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default HomePage;
