// src/pages/ViewData.js

import React, { useState, useMemo, useRef, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Autocomplete,
  ListItem,
  Grid,
  Snackbar,
  Alert,
  Dialog,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import HomePage from "./HomePage";
import { debounce } from "lodash";
import VirtualizedList from "../components/VirtualizedList";
import useMediaQuery from "@mui/material/useMediaQuery";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

// Custom hook to detect double-tap on touch devices
const useDoubleTap = (callback, delay = 600) => {
  const tapCount = useRef(0);
  const timer = useRef(null);

  const handleTouchStart = useCallback(
    (event) => {
      tapCount.current += 1;

      if (tapCount.current === 1) {
        // Start the timer on the first tap
        timer.current = setTimeout(() => {
          tapCount.current = 0;
        }, delay);
      }

      if (tapCount.current === 3) {
        callback(event);
        clearTimeout(timer.current);
        tapCount.current = 0;
      }
    },
    [callback, delay]
  );

  return handleTouchStart;
};

const ViewData = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // md and up for desktops and laptops

  // State to hold symbol options
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [loadingSymbols, setLoadingSymbols] = useState(false);
  const [searchError, setSearchError] = useState(null);

  // State for date pickers
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // State to hold table data and headers
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);

  // State for sorting
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  // Loading and error states
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusMsg, setStatusMsg] = useState("");

  // State for hidden headers
  const [hiddenHeaders, setHiddenHeaders] = useState([]);

  // State for notifications
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // State for full-screen mode
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Debounce the API call to prevent excessive requests
  const fetchSymbolOptions = useMemo(
    () =>
      debounce(async (query) => {
        if (!query || query.trim().length < 1) {
          setSymbolOptions([]);
          return;
        }
        setLoadingSymbols(true);
        setSearchError(null);
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            "https://api.arasu.online/api/Data/GetSymbols",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: { query: query },
            }
          );

          if (response.data && Array.isArray(response.data)) {
            setSymbolOptions(response.data);
          } else {
            setSymbolOptions([]);
          }
        } catch (err) {
          console.error("Error fetching symbol options:", err);
          setSearchError("Failed to fetch symbols.");
        } finally {
          setLoadingSymbols(false);
        }
      }, 300),
    []
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    fetchSymbolOptions(newInputValue);
  };

  // Handle symbol selection
  const handleSymbolChange = (event, newValue) => {
    setSelectedSymbol(newValue);
  };

  // Function to handle data retrieval
  const handleSubmit = async () => {
    if (!selectedSymbol || !fromDate || !toDate) {
      setError("Please select a symbol and date range.");
      return;
    }
    setIsLoading(true);
    setError(null);
    setStatusMsg("");

    try {
      const token = localStorage.getItem("token");
      const payload = {
        symbol: selectedSymbol,
        fromDate: fromDate,
        toDate: toDate,
      };

      const response = await axios.post(
        "https://api.arasu.online/api/Data/GetDailyData",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);

      if (Array.isArray(response.data)) {
        setTableData(response.data);

        // Set dynamic headers based on the first entry
        if (response.data.length > 0) {
          const dynamicHeaders = Object.keys(response.data[0]).map((key) => ({
            label: key,
            value: key,
            color: getHeaderColor(key),
          }));
          setHeaders(dynamicHeaders);

          // By default, no headers are hidden when new headers are set
          setHiddenHeaders([]);
        } else {
          setHeaders([]);
          setHiddenHeaders([]);
        }
      } else {
        setTableData([]);
        setHeaders([]);
        setHiddenHeaders([]);
        setError("Invalid data format received from server.");
      }

      setStatusMsg("Data retrieved successfully.");
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to retrieve data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to assign specific colors based on key
  const getHeaderColor = (key) => {
    switch (key) {
      // Green for price
      case "O_C_D_TH":
        return theme.palette.success.main; // Green
      case "H_C_D_TH":
        return theme.palette.success.main;
      case "L_C_D_TH":
        return theme.palette.success.main;
      case "C_C_D_TH":
        return theme.palette.success.main;
      // Orange for volume
      case "O_C_D_PD":
        return theme.palette.warning.main; // Orange
      case "H_C_D_PD":
        return theme.palette.warning.main;
      case "L_C_D_PD":
        return theme.palette.warning.main;
      case "C_C_D_PD":
        return theme.palette.warning.main;
      // Purple for custom headers
      case "C_O":
      case "C_H":
      case "C_L":
        return "#9847f5"; // Custom color
      default:
        return theme.palette.primary.main; // Default primary color
    }
  };

  // Function to handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);

    const sortedData = [...tableData].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      // Handle undefined or null values
      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      // Numeric sorting
      if (typeof aValue === "number" && typeof bValue === "number") {
        return isAsc ? aValue - bValue : bValue - aValue;
      }

      // Date sorting
      if (isValidDate(aValue) && isValidDate(bValue)) {
        return isAsc
          ? new Date(aValue) - new Date(bValue)
          : new Date(bValue) - new Date(aValue);
      }

      // String sorting
      return isAsc
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });

    setTableData(sortedData);
  };

  // Helper function to check if a string is a valid date
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date);
  };

  // Function to handle header selection change
  const handleHeaderChange = (event) => {
    const value = event.target.value;

    if (value.includes("select_all")) {
      if (hiddenHeaders.length === headers.length) {
        setHiddenHeaders([]); // Unhide all if all are hidden
      } else {
        setHiddenHeaders(headers.map((header) => header.value)); // Hide all if not all are hidden
      }
    } else {
      setHiddenHeaders(value);
    }
  };


  const navigate = useNavigate();

  const handleAction = (row) => {
    const { DateTime } = row;
  
    // Extract the date part from the datetime string
    const date = DateTime.split(" ")[0];
  
    // Ensure selectedSymbol is defined and encrypted
    if (!selectedSymbol) {
      console.error("selectedSymbol is not defined.");
      return;
    }
  
    // Define your encryption key (Keep this secure!)
    const encryptionKey = 'your-secure-key'; // Replace with a secure key
  
    // Encrypt the selectedSymbol and date
    const encryptedSymbol = CryptoJS.AES.encrypt(selectedSymbol, encryptionKey).toString();
    const encryptedDate = CryptoJS.AES.encrypt(date, encryptionKey).toString();
    const user = localStorage.getItem("userId");

    if(!user){
     navigate("/")
    }
  
    // Construct the URL with encrypted parameters
    const url = `${window.location.origin}/fifteen-min-data?symbol=${encodeURIComponent(
      encryptedSymbol
    )}&date=${encodeURIComponent(encryptedDate)}&uuu=${encodeURIComponent(user)}`;
  
    // Open the URL in a new tab
    window.open(url, "_blank");
  };



  // Function to handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  // Functions to handle Full Screen mode
  const handleFullScreenOpen = () => {
    setIsFullScreen(true);
  };

  const handleFullScreenClose = () => {
    setIsFullScreen(false);
  };

  // Function to handle double tap for mobile
  const handleDoubleTap = useDoubleTap(() => {
    setIsFullScreen((prev) => !prev);
  }, 1000);

  return (
    <>
      <HomePage />

      {/* Input Area */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { xs: 5, sm: 8, md: 10 },
          flexDirection: "column",
          alignItems: "center",
          px: { xs: 2, sm: 0 },
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: "70%", md: "60%", lg: "40%" },
            p: 4,
            borderRadius: "16px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* Symbol Selection */}
              <Autocomplete
                options={symbolOptions}
                getOptionLabel={(option) => option}
                value={selectedSymbol}
                onChange={handleSymbolChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                loading={loadingSymbols}
                ListboxComponent={VirtualizedList}
                renderOption={(props, option) => (
                  <ListItem {...props} key={option}>
                    {option}
                  </ListItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Symbol"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingSymbols ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                noOptionsText={
                  inputValue.trim().length < 1
                    ? "Type at least 1 character"
                    : "No symbols found"
                }
              />

              {searchError && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ marginTop: theme.spacing(1), textAlign: "center" }}
                >
                  {searchError}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Controls: Header Selector */}
              {tableData.length > 0 && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="header-select-label">Hide Columns</InputLabel>
                  <Select
                    labelId="header-select-label"
                    id="header-select"
                    multiple
                    value={hiddenHeaders}
                    onChange={handleHeaderChange}
                    label="Hide Columns"
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>None</em>;
                      }

                      if (selected.length === headers.length) {
                        return "All Columns Hidden";
                      }

                      return `${selected.length} column(s) hidden`;
                    }}
                  >
                    {/* Select All Option */}
                    <MenuItem key="select_all" value="select_all">
                      <Checkbox
                        checked={
                          hiddenHeaders.length === headers.length &&
                          headers.length > 0
                        }
                        indeterminate={
                          hiddenHeaders.length > 0 &&
                          hiddenHeaders.length < headers.length
                        }
                      />
                      <ListItemText primary="Select All" />
                    </MenuItem>

                    {/* Individual Options */}
                    {headers.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        <Checkbox checked={hiddenHeaders.includes(item.value)} />
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Select From Date"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={fromDate || ""}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Select To Date"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={toDate || ""}
                onChange={(e) => setToDate(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* Submit Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 3,
              fontWeight: "bold",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Get Data"}
          </Button>
          {/* Status or Error Messages */}
          {statusMsg && (
            <Typography
              variant="body1"
              color="success.main"
              sx={{ mt: 2, textAlign: "center" }}
            >
              {statusMsg}
            </Typography>
          )}
          {error && (
            <Typography
              variant="body1"
              color="error"
              sx={{ mt: 2, textAlign: "center" }}
            >
              {error}
            </Typography>
          )}

          {/* Full Screen Button - Only on Desktop/Laptop */}
          {isDesktop && tableData.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFullScreenOpen}
              sx={{ mt: 2, fontWeight: "bold" }}
              aria-label="Enter Full Screen Mode"
            >
              Full Screen
            </Button>
          )}
        </Box>
      </Box>

      {/* Data Display Area */}
      {tableData.length > 0 && (
        <Box
          sx={{
            width: { xs: "100%", sm: "95%", md: "95%", lg: "95%" }, // Full width
            margin: "auto",
            mt: 5,
            mb: 5,
            px: { xs: 2, sm: 4, md: 6 },
            pb: 5,
          }}
        >
          {/* Data Table */}
          <TableContainer
            component={Paper}
            sx={{ maxHeight: "80vh", width: "100%", marginBottom:"10px" }}
            onTouchStart={!isDesktop ? handleDoubleTap : undefined} // Attach double-tap handler for mobile
          >
            <Table stickyHeader aria-label="stock data table" sx={{ minWidth: "100%" }}>
              <TableHead>
                <TableRow>
                  {headers
                    .filter((header) => !hiddenHeaders.includes(header.value))
                    .map((header) => (
                      <StyledTableCell
                        key={header.value}
                        bgcolor={header.color} // Apply specific color
                        bordercolor={header.color}
                        onClick={() => header.value && handleSort(header.value)}
                        sx={{
                          cursor: header.value ? "pointer" : "default",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {header.label}
                          {orderBy === header.value ? (
                            order === "asc" ? (
                              <ArrowUpward fontSize="small" />
                            ) : (
                              <ArrowDownward fontSize="small" />
                            )
                          ) : null}
                        </Box>
                      </StyledTableCell>
                    ))}

                  {/* Extra header for Actions */}
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      backgroundColor:
                        index % 2 === 0
                          ? theme.palette.action.hover
                          : theme.palette.background.default,
                    }}
                  >
                    {headers
                      .filter((header) => !hiddenHeaders.includes(header.value))
                      .map((header) => (
                        <TableCell
                          key={header.value}
                          sx={{
                            textAlign: "center",
                            padding: "8px",
                            borderLeft: `1px solid ${
                              header.color || theme.palette.divider
                            }`,
                          }}
                        >
                          {row[header.value]}
                        </TableCell>
                      ))}

                    {/* Action Button */}
                    <TableCell
                      sx={{
                        textAlign: "center",
                        padding: "8px",
                        borderLeft: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <ActionButton
                        variant="contained"
                        onClick={() => handleAction(row)}
                      >
                        View 15Min
                      </ActionButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Full Screen Dialog */}
      <Dialog
        fullScreen
        open={isFullScreen}
        onClose={handleFullScreenClose}
        TransitionProps={{
          onEscapeKeyDown: handleFullScreenClose,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
        

          {/* Full Screen Data Table */}
          <Box sx={{ flexGrow: 1, p: 2 }}>
            <TableContainer
              component={Paper}
              sx={{
                marginBottom:"100px",
                maxHeight: isFullScreen ? "90%" : "90%",
                position: isFullScreen ? "fixed" : "relative",
                top: isFullScreen ? 0 : "auto",
                left: isFullScreen ? 0 : "auto",
                zIndex: isFullScreen ? 2000 : "auto",
                overflow: "auto"
                
              }}
              onTouchStart={!isDesktop ? handleDoubleTap : undefined} // Attach double-tap handler for mobile
            >
              <Table stickyHeader aria-label="stock data table full screen" sx={{ minWidth: "100%", marginBottom:"20px" }}>
                <TableHead>
                  <TableRow>
                    {headers
                      .filter((header) => !hiddenHeaders.includes(header.value))
                      .map((header) => (
                        <StyledTableCell
                          key={header.value}
                          bgcolor={header.color} // Apply specific color
                          bordercolor={header.color}
                          onClick={() => header.value && handleSort(header.value)}
                          sx={{
                            cursor: header.value ? "pointer" : "default",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {header.label}
                            {orderBy === header.value ? (
                              order === "asc" ? (
                                <ArrowUpward fontSize="small" />
                              ) : (
                                <ArrowDownward fontSize="small" />
                              )
                            ) : null}
                          </Box>
                        </StyledTableCell>
                      ))}

                    {/* Extra header for Actions */}
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow
                      key={index}
                      hover
                      sx={{
                        backgroundColor:
                          index % 2 === 0
                            ? theme.palette.action.hover
                            : theme.palette.background.default,
                      }}
                    >
                      {headers
                        .filter((header) => !hiddenHeaders.includes(header.value))
                        .map((header) => (
                          <TableCell
                            key={header.value}
                            sx={{
                              textAlign: "center",
                              padding: "8px",
                              borderLeft: `1px solid ${
                                header.color || theme.palette.divider
                              }`,
                            }}
                          >
                            {row[header.value]}
                          </TableCell>
                        ))}

                      {/* Action Button */}
                      <TableCell
                        sx={{
                          textAlign: "center",
                          padding: "8px",
                          borderLeft: `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        <ActionButton
                          variant="contained"
                          onClick={() => handleAction(row)}
                        >
                          View 15Min
                        </ActionButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Dialog>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={notification.severity}
          sx={{
            width: "100%",
            fontSize: { xs: "0.8rem", sm: "1rem" },
            padding: { xs: 1, sm: 2 },
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ViewData;

// Styled components for better readability and maintainability
const StyledTableCell = styled(TableCell)(({ theme, bgcolor, bordercolor }) => ({
  fontWeight: "bold",
  color: theme.palette.common.white,
  backgroundColor: bgcolor || theme.palette.primary.main,
  textAlign: "center",
  borderLeft: `1px solid ${bordercolor || theme.palette.divider}`,
  cursor: "pointer",
  userSelect: "none",
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  padding: "6px 16px",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.common.white,
  },
  textTransform: "none",
}));
