// src/pages/StockDatas.js
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Stack,
  Grid,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomePage from "./HomePage";
import axios from "axios";

// Styled components for enhanced styling
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: "bold",
  fontSize: "0.9rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    padding: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "0.9rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    padding: theme.spacing(1),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
    cursor: "pointer",
  },
}));

const ErrorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(3),
}));


const StockDatas = () => {
  const [symbols, setSymbols] = useState([]);
  const [records, setRecords] = useState([]);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState([]); // State for selected rows
  const [open, setOpen] = useState(false); // State for confirmation dialog
  const [loading, setLoading] = useState(false); // State for loading
  const [message, setMessage] = useState(""); // Feedback message
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state

  // Replace with your backend API URLs
  const API_URL = "https://api.arasu.online/api/marketcap/freefloat-issued-sizes";
  const FETCH_ALL_URL = "https://api.arasu.online/api/stock/fetch-all";
  const DELETE_ALL_URL = "https://api.arasu.online/api/stock/delete-all-stocks";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Optionally, fetch initial data here
    fetchData();
  }, []);
  const ResponsiveBox = styled(Box)(({ theme }) => ({
    width: "100%",
    maxWidth: isMobile ? 300 : 1600,
    margin: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  }));
  
  const fetchData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSymbols(data.symbols);
      setRecords(data.records);
      setMessage("Data fetched successfully.");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
      setMessage("Error loading data. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Handler for selecting all rows
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = records.map((n) => n.date);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Handler for selecting a single row
  const handleClick = (event, date) => {
    const selectedIndex = selected.indexOf(date);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, date);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Handler for opening the confirmation dialog
  const handleOpen = () => {
    setOpen(true);
  };

  // Handler for closing the confirmation dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handler for confirming deletion
  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(DELETE_ALL_URL);

      if (response.status === 204) {
        setRecords([]); // Clear all records from the frontend
        setMessage("All records deleted successfully.");
      } else {
        setMessage("Unexpected response from server.");
      }
    } catch (error) {
      console.error("Error deleting records:", error);
      // Handle specific error messages
      if (error.response) {
        setMessage(error.response.data.message || "Error deleting records.");
      } else {
        setMessage("Network error. Please try again.");
      }
    } finally {
      setSelected([]); // Clear any selections
      setOpen(false); // Close any open dialogs
      setSnackbarOpen(true); // Show the snackbar with the message
      setLoading(false);
    }
  };

  const isSelected = (date) => selected.indexOf(date) !== -1;

  const handleFetchAll = async () => {
    setLoading(true);
    setMessage("Fetching data...");
    setSnackbarOpen(true);
    try {
      const response = await axios.post(FETCH_ALL_URL);
      setMessage(response.data.Message || "Data fetched successfully.");
      await fetchData(); // Refresh data after fetching
    } catch (error) {
      setMessage("Error fetching data.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Handler for closing the Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  if (error) {
    return (
      <>
        <HomePage />
        <ErrorContainer>
          <Typography variant="h6" color="error" align="center">
            Error loading data. Please try again later.
          </Typography>
        </ErrorContainer>
      </>
    );
  }

  return (
    <>
      <HomePage />
      <Stack direction={isMobile ? "column" : "row"} spacing={2} ml={isMobile ? 2 : 4}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleFetchAll}
          disabled={loading}
          fullWidth={isMobile}
          sx={{
            maxWidth: isMobile ? "260px" : "300px",
            minHeight: 50,
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Mac Data Download"
          )}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={fetchData}
          fullWidth={isMobile}
          sx={{
            maxWidth: isMobile ? "260px" : "200px",
            minHeight: 50,
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
          }}
        >
          Stock Data View
        </Button>
      </Stack>
      <ResponsiveBox>
        <Grid container spacing={2} justifyContent="start">
          {/* Button Container using Stack for better responsiveness */}
          <Grid item xs={12}></Grid>

          {/* Toolbar with Delete Button */}
          {selected.length > 0 && (
            <Grid item xs={12}>
              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpen}
                  fullWidth={isMobile}
                  sx={{
                    maxWidth: isMobile ? "100%" : "150px",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: theme.palette.error.dark,
                    },
                  }}
                >
                  Delete
                </Button>
                <Typography
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  align={isMobile ? "center" : "left"}
                >
                  {selected.length} selected
                </Typography>
              </Stack>
            </Grid>
          )}

          {/* Confirmation Dialog */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle id="delete-dialog-title">
              {"Confirm Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                Are you sure you want to delete the selected records?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmDelete} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          {/* Table Container with Responsive Overflow */}
          <Grid item xs={12}>
            <Box sx={{ overflowX: "auto" }}>
              {records.length > 0 && (
                <StyledTableContainer component={Paper}>
                  <Table
                    aria-label="market cap table"
                    size={isMobile ? "small" : "medium"}
                    sx={{
                      minWidth: 650,
                      "@media (max-width:600px)": {
                        minWidth: "100%",
                      },
                    }}
                  >
                    <StyledTableHead>
                      <TableRow>
                        <StyledTableCellHeader padding="checkbox">
                          <Checkbox
                            color="primary"
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < records.length
                            }
                            checked={
                              records.length > 0 &&
                              selected.length === records.length
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{
                              "aria-label": "select all records",
                            }}
                          />
                        </StyledTableCellHeader>
                        <StyledTableCellHeader>
                          <Typography variant={isMobile ? "body2" : "body1"}>
                            Date
                          </Typography>
                        </StyledTableCellHeader>
                        {symbols.map((symbol) => (
                          <StyledTableCellHeader key={symbol}>
                            <Typography variant={isMobile ? "body2" : "body1"}>
                              {symbol}
                            </Typography>
                          </StyledTableCellHeader>
                        ))}
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {records.map((record) => {
                        const isItemSelected = isSelected(record.date);
                        const labelId = `enhanced-table-checkbox-${record.date}`;

                        return (
                          <StyledTableRow
                            hover
                            onClick={(event) => handleClick(event, record.date)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={record.date}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <StyledTableCell component="th" scope="row">
                              <Typography
                                variant={isMobile ? "body2" : "body1"}
                              >
                                {new Date(record.date).toLocaleDateString()}
                              </Typography>
                            </StyledTableCell>
                            {symbols.map((symbol) => (
                              <StyledTableCell key={symbol}>
                                <Typography
                                  variant={isMobile ? "body2" : "body1"}
                                >
                                  {record.freefloatIssuedSizes[symbol]
                                    ? record.freefloatIssuedSizes[
                                        symbol
                                      ].toLocaleString()
                                    : "N/A"}
                                </Typography>
                              </StyledTableCell>
                            ))}
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
            </Box>
          </Grid>

          {/* No Data Message */}
          {!loading && records.length === 0 && (
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center", mt: 5 }}>
                <Typography variant="h6">No data available.</Typography>
              </Box>
            </Grid>
          )}

          {/* Loading Indicator */}
          {loading && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CircularProgress size={isMobile ? 24 : 32} color="inherit" />
              </Box>
            </Grid>
          )}

          {/* Snackbar for Feedback Messages */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
              vertical: isMobile ? "bottom" : "top",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={error ? "error" : "success"}
              sx={{
                width: "100%",
                fontSize: isMobile ? "0.8rem" : "1rem",
                padding: isMobile ? theme.spacing(1) : theme.spacing(2),
              }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Grid>
      </ResponsiveBox>
    </>
  );
};

export default StockDatas;
